import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Email } from 'src/app/data/email';
import { EmailService } from 'src/app/data/email.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit {
  @Input() contact: {mls:string, name:string, email:string};

  questions = {
    'moreinfo': "Please send me more information:",
    'showing': "I'd like to view this home",
  }
  // contactForm: FormGroup;
  contactForm = this.formBuilder.group({
    question: ['showing', [Validators.required]],
    name: ['', [Validators.required, Validators.minLength(3)]],
    email: ['', [Validators.required, Validators.email]],
    // dob: [this.defaultDate],
    cell: ['', [Validators.pattern('^[0-9- ]+$'), Validators.required]],
    message: ['', [Validators.required, Validators.minLength(3)]],
  })

  // defaultDate = '1960-01-01';
  isSubmitted = false;

  constructor(
    // private rootFormGroup: FormGroupDirective,
    private formBuilder: FormBuilder,
    
    // private http: HttpClient,
    private emailService: EmailService,
  ) { }

  ngOnInit() {
    // console.log("in contact-form component");
    // console.log(this.contact);
    // this.contactForm = this.rootFormGroup.control;
    // console.log(this.rootFormGroup.control.controls);
    // console.log(this.rootFormGroup.control.value);
    // console.log(this.contact)
  }

  getDate(e) {
    let date = new Date(e.target.value).toISOString().substring(0,10);
    this.contactForm.get('dob').setValue(date, {
      onlyself:true
    })
  }

  get errorControl() {
    return this.contactForm.controls;
  }

  submitForm() {
    this.isSubmitted = true;
    if (!this.contactForm.valid) {
      console.log('Please provide all the required values!');
      return false;
    }
    console.log(this.contactForm.value);
    console.log("sending email");
    let email:Email = {
          to_name : this.contact.name,
          to_email : this.contact.email,
          from_name: this.contact.name,
          from_email: this.contact.email,
          subject: `MLS:${this.contact.mls} ` + this.questions[this.contactForm.value.question],
          message: `Sent from: ${this.contactForm.value.name} <${this.contactForm.value.email}>\r\n\r\n`
          + this.contactForm.value.message,
        }
    
    this.emailService.sendEmail(email);
    alert("Email sent");
    this.isSubmitted = false;
    this.contactForm.reset();
  }

}
