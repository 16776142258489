import { Injectable } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { Area, vowArea, vowType, vowStyle } from './vowcode';
// import { MessagesService } from '../messages/messages.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map, tap, switchMap, exhaustMap } from 'rxjs/operators';
// import { LogMessageService } from '../message/log-message/log-message.service';
// import { AlertMessageService } from '../message/alert-message/alert-message.service';
import { Age } from './age';
// import { LogMessageService } from '../message/log-message.service';
// import { AlertMessageService } from '../message/alert-message.service';
// import { AlertMessageService } from 'projects/alert-message/src/public-api';
// import { LogMessageService } from 'projects/log-message/src/public-api';
// import { Codes } from './codes';
// import { WP_Posts } from './wp_posts';

// const httpOptions = {
//   headers: new HttpHeaders(
//     { 'Content-type': 'application/json' }
//   )
// };


@Injectable({
  providedIn: 'root'
})

export class VowcodeService {

  constructor(
    private http: HttpClient,
//     private logMessage: LogMessageService,
//     private alertMessage: AlertMessageService,
    // private myService: MessagesService,
  ) { }

  Category: string;
  message = new Subject<string>();

  private vowcodeUrl : string;
  error = new Subject<string>();
  private firebaseURL = 'https://visage-82084.firebaseio.com';
  // private postUrl = 'https://admin.visagehome.com/php/getpost.php';

  getDomainName() {
    // let hostName = window.location.hostname;
    // // console.log(`hostname=${this.hostName}`);
    // let domainName = hostName == '192.168.1.38' ? 'visagehome.com' : hostName.replace(/www./,'');
    // this.vowcodeUrl = `https://backend.${domainName}/php/vowcode.php`;
    this.vowcodeUrl = `https://backend.visagehome.com/php/vowcode.php`;
    // console.log(`vowUrl = ${this.vowUrl}`);
  }

  /** GET hero by id. Will 404 if id not found */
  getArea(id: number): Observable<vowArea> {
    this.getDomainName();

    const url = `${this.vowcodeUrl}?id=${id}`;
    // console.log(url);
    // const params = new HttpParams()
    //   .set('id', `${id}`);
    return this.http.get<vowArea>(url).pipe(
      tap(_ => this.log(`fetched area id=${id}`)),
      catchError(this.handleError<vowArea>(`getArea id=${id}`))
    );
  }

  getAreas(): Observable<string[]> {
    this.getDomainName();
    let url = `${this.vowcodeUrl}?t=la`;
    // console.log(url);
    return this.http.get<string[]>(url)
      .pipe(
        tap(_ => this.log(`getAreas `)),
        catchError(this.handleError<string[]>('getAreas', []))
      );
  }

  getMunis(area: string): Observable<string[]> {
    this.getDomainName();
    let url = `${this.vowcodeUrl}?t=lm&a=${area}`;
    // console.log(url);
    return this.http.get<string[]>(url)
      .pipe(
        tap(_ => this.log(`getMunis area=${area} `)),
        catchError(this.handleError<string[]>('getMunis', []))
      );
  }
  
  listMunis(): Observable<string[]> {
    this.getDomainName();
    let url = `${this.vowcodeUrl}?t=lmuni`;
    console.log(url);
    return this.http.get<string[]>(url)
      .pipe(
        tap((res:string[]) => console.log(`listMunis ${res}`)),
        catchError(this.handleError<string[]>('listMunis', []))
      );
  }
  
  searchMuni(muni: string): Observable<string[]> {
    let url = `${this.vowcodeUrl}?searchMuni=${muni}`;
    // console.log(`searchMuni url=${url}`);
    return this.http.get<string[]>(url)
      .pipe(
        tap(_ => this.log(`searchMunis muni=${muni} `)),
        catchError(this.handleError<string[]>('searchMunis', []))
      );
  }

  Muni2Comm(muni:string): Observable<string[]> {
    this.getDomainName();
    let url = `${this.vowcodeUrl}?m2c=${muni}`;
    // console.log(url);
    return this.http.get<string[]>(url)
      .pipe(
        tap(_ => this.log(`Muni2Comm muni=${muni} `)),
        catchError(this.handleError<string[]>('Muni2Comm', []))
      );
  }

  Comm2Muni(comm:string): Observable<string> {
    this.getDomainName();
    let url = `${this.vowcodeUrl}?c2m=${comm}`;
    // console.log(url);
    return this.http.get<string>(url)
      .pipe(
        tap(_ => this.log(`Comm2Muni comm=${comm} `)),
        catchError(this.handleError<string>('Comm2Muni'))
      );
  }

  getComms(area: string, muni: string): Observable<string[]> {
    this.getDomainName();
    let url = `${this.vowcodeUrl}?t=lc&a=${area}&m=${muni}`;
    // console.log(url);
    return this.http.get<string[]>(url)
      .pipe(
        tap(_ => this.log(`getComms area=${area} muni=${muni}`)),
        catchError(this.handleError<string[]>('getComms', []))
      );
  }

  getTypes(cls: string): Observable<string[]> {
    this.getDomainName();
    let url = `${this.vowcodeUrl}?t=lt&cls=${cls}`;
    // console.log(url);
    return this.http.get<string[]>(url)
      .pipe(
        tap(_ => {
          // console.log(_);
        }),
        catchError(this.handleError<string[]>('getTypes', []))
      );
  }

  getStyles(cls: string): Observable<string[]> {
    this.getDomainName();
    let url = `${this.vowcodeUrl}?t=ls&cls=${cls}`;
    // console.log(url);
    return this.http.get<string[]>(url)
      .pipe(
        tap(_ => this.log(`getStyles class=${cls}`)),
        catchError(this.handleError<string[]>('getStyles', []))
      );
  }

  getAges(): Observable<Age[]> {
    this.getDomainName();
    let url = `${this.vowcodeUrl}?t=lage`;
    // console.log(url);
    return this.http.get<Age[]>(url)
      .pipe(
        tap(_ => {
          // console.log(_);
          this.log(`getAges `)
        }),
        catchError(this.handleError<Age[]>('getAges', []))
      );
  }

  fetchAreas(): Observable<string[]> {
    // console.log(`fetchAreas: cat=${cat} count=${count} status=${status}`)
    let url = `${this.firebaseURL}/area.json?shallow=true`;

    return this.http
      .get<any>(url)
      .pipe(
        // tap(_ => this.log(`fetched areas cat=${cat} count=${count} status=${status}`)),
        map((responseData: { [key: string]: string }) => {
          // console.log(responseData);
          let areaArray: string[] = [];
          // let idx:number = 0;
          for (const key in responseData) {
            // console.log(`key=${key}`);
            areaArray.push(key);
          }
          areaArray.sort((a, b) => (a > b) ? 1 : -1);
          return areaArray;
        }),
        catchError(err => {
          console.log(err);
          this.alert('Danger', `Error fetching areas`);
          return throwError(err);
        })
      )
  }

  fetchMunis(area: string): Observable<string[]> {
    // console.log(`fetchMunis: area=${area}`);
    // console.log(`fetchAreas: cat=${cat} count=${count} status=${status}`)
    let url = `${this.firebaseURL}/area/${area}.json?shallow=true`;
    // if (area) url += `?orderBy="area"&equalTo="${area}"`;

    return this.http
      .get<any>(url)
      .pipe(
        // tap(_ => this.log(`fetched areas cat=${cat} count=${count} status=${status}`)),
        map((responseData: { [key: number]: Area }) => {
          // console.log(responseData);
          let muniArray: string[] = [];
          // let idx:number = 0;
          for (const key in responseData) {
            // console.log(`key=${key}`);
              muniArray.push(key);
          }
          muniArray.sort((a, b) => (a > b) ? 1 : -1);
          return muniArray;
        }),
        catchError(err => {
          console.log(err);
          this.alert('Danger', `Error fetching municipality for area=${area}`);
          return throwError(err);
        })
      )
  }

  fetchComms(area: string, muni: string): Observable<string[]> {
    // console.log(`fetchComms: area=${area} muni=${muni}`);
    let url = `${this.firebaseURL}/area/${area}/${muni}.json`;
    // if (area) url += `?orderBy="Community"&equalTo="${area}"`;
    // console.log(url);
    return this.http
      .get<any>(url)
      .pipe(
        // tap(_ => this.log(`fetched areas cat=${cat} count=${count} status=${status}`)),
        map((responseData: { [key: number]: Area }) => {
          // console.log(responseData);
          let commArray: string[] = [];
          // let idx:number = 0;
          for (const key in responseData) {
            // console.log(`key=${key}`);
            if (responseData.hasOwnProperty(key) && responseData[key]) {
              commArray.push(responseData[key].community);
            }
          }
          commArray.sort((a, b) => (a > b) ? 1 : -1);
          return commArray;
        }),
        catchError(err => {
          console.log(err);
          this.alert('Danger', `Error fetching community for area=${area} muni=${muni}`);
          return throwError(err);
        })
      )
  }

  fetchArea(name: string): Observable<Area> {
    let searchParams = new HttpParams();
    searchParams = searchParams
      .append('print', 'pretty')
      // .append('custom','key')
      ;

    return this.http
      .get<Area>(
        `${this.firebaseURL}/area/${name}.json`,
        {
          headers: new HttpHeaders({ 'Custom-Header': 'Hello' }),
          // params: new HttpParams().set('print','pretty')
          params: searchParams,
          responseType: 'json'
        }
      )
      .pipe(
        map(responseData => {
          // console.log(responseData);
          return responseData;
        }),
        catchError(err => {
          // Send to analytics server
          console.log(err);
          this.alert('Danger', `Error fetching area name=${name}`);
          return throwError(err);
        })
      )

  }

  fetchAreaByEmail(email: string): Observable<Area> {
    // let searchParams = new HttpParams();
    // searchParams = searchParams
    //   .append('print', 'pretty')
    //   // .append('custom','key')
    //   ;
    let url = `${this.firebaseURL}/area.json`;
    url += `?orderBy="email"&equalTo="${email}"`;
    // console.log("url=" + url);

    return this.http
      .get<Area>(url)
      .pipe(
        map(responseData => {
          // console.log(responseData);
          for (const key in responseData) {
            // console.log(`key=${key}`);
            if (responseData.hasOwnProperty(key) && responseData[key]) {
              // console.log(responseData[key]);
              return responseData[key];
            }
          }
        }),
        catchError(err => {
          // Send to analytics server
          console.log(err);
          this.alert('Danger', `Error fetching area email=${email}`);
          return throwError(err);
        })
      )

  }
  //////// Save methods //////////

  // initArea(): Observable<Area> {
  //   // return new Observable<Area>();
  //   const url = `${this.vowcodeUrl}?new`;
  //   // console.log(url);
  //   return this.http.get<Area>(url)
  //     .pipe(
  //       tap(_ => this.log(`init area`)),
  //       catchError(this.handleError<Area>(`init Area`))
  //     );

  // }


  /** POST: add a new area to the server */
  addArea(area: Area): Observable<any> {
    // let key = form.frm_id;
    // let postData = { [form.frm_id] : form } ;

    return this.http
      .put<{ resp: any }>(
        `${this.firebaseURL}/area/${area}.json`,
        area)
      .pipe(
        catchError(err => {
          console.log(err);
          this.alert('Danger', `Error adding area ${area.area}`)
          return throwError(err);
        })
      )
    // catchError(_ =>{})
  }

  /** DELETE: delete the area from the server */
  deleteArea(name: string): Observable<any> {

    return this.http.delete<{ resp: any }>(
      `${this.firebaseURL}/area/${name}.json`
    ).pipe(

      catchError(err => {
        console.log(err);
        this.alert('Danger', `Error deleting area ${name}`)
        return throwError(err);
      })
    );
  }

  /** PUT: update the hero on the server */
  // updateArea(area: Area): Observable<any> {
  //   return this.http.put(this.vowcodeUrl, area, httpOptions).pipe(
  //     tap(_ => this.log(`updated area id=${area.area_id}`)),
  //     catchError(this.handleError<any>('updateArea'))
  //   );
  // }

  updateArea(area: Area): Observable<any> {

    return this.http.put<{ resp: any }>(
      `${this.firebaseURL}/area/${area.area}.json`,
      area
    ).pipe(
      catchError(err => {
        console.log(err);
        this.alert('Danger', `Error updating area ${area.area}`)
        return throwError(err);
      })
    )
  }


  /* @param operation - name of the operation that failed
  * @param result - optional value to return as the observable result
  */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a BhffService message with the MessageService  */
  private log(message: string) {
//     this.logMessage.add(`AreaService: ${message}`);
  }

  private alert(type: string, message: string) {
//     this.alertMessage.add(type, `AreaService: ${message}`);

  }

}
