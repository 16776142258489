import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Hotlist } from 'src/app/data/hotlist';
import { HotlistService } from 'src/app/data/hotlist.service';
import { Vow } from 'src/app/data/vow';
import { VowService } from 'src/app/data/vow.service';
import { Website } from 'src/app/data/website';
import { WebsiteService } from 'src/app/data/website.service';

@Component({
  selector: 'app-vow-hotlist',
  templateUrl: './vow-hotlist.component.html',
  styleUrls: ['./vow-hotlist.component.scss'],
})
export class VowHotlistComponent implements OnInit {
  @Input() name: string;
  website: Website;
  hotlist: Hotlist;
  hotlists: Vow[] = [];
  hotlistId: string;
  hotlistDelay: number;
  hotlistName: string;
  hotlistTag: string;
  hotlistTotal: number;
  hotlistMax: number = 10;
  isLoading: boolean = false;
  onSale = ['New', 'Pc ', 'Sc ', 'Lc '];

  constructor(
    private vowService: VowService,
    private websiteService: WebsiteService,
    private hotlistService: HotlistService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.hotlistName = this.name;
    this.hotlistId = this.hotlistService.genId(this.name);
    this.hotlistDelay = Math.floor(Math.random() * 2000) + 3000;
    console.log(`hotlist ${this.name} id=${this.hotlistId} delay=${this.hotlistDelay}`);
    let hostName = window.location.hostname;
    let domainName = hostName == '192.168.1.38' ? 'gtahomeindex.com' : hostName.replace(/www./, '');
    // console.log(`domain=${domainName}`);
    // domainName = domainName.replace(/\./g, '-');
    this.websiteService.fetchWebsite(domainName).subscribe((web: Website) => {
      // console.log(web);
      this.website = Object.assign({}, web);
      // console.log(this.website);
      this.hotlistService.fetchHotlist(this.hotlistId).subscribe((hot: Hotlist) => {
        this.hotlist = Object.assign({}, hot);
        // console.log(this.hotlist);
        this.getHotlist();
      })
    })
  }

  getHotlist() {
    // console.log(this.vow.vow_userrole);
    this.isLoading = true;
    this.hotlistTag = `Properties in ${this.website.municipality} for Sale`;

    // this.isLoading = true;
    this.hotlistTotal = 0;
    this.vowService.getHotlist(this.hotlist, this.website, null)
      .subscribe(resp => {
        // console.log(resp);
        let vowArray = [];
        // this.page = 1;
        this.hotlists = [];

        for (const key in resp) {
          // console.log(`key=${key}`);
          if (resp.hasOwnProperty(key) && resp[key]) {
            vowArray.push({ ...resp[key] });
          }
        }
        this.hotlistTotal = vowArray.length;
        // console.log(this.hotlistTotal);
        let limit = this.hotlistMax;
        if (limit > this.hotlistTotal) {
          limit = this.hotlistTotal;
        }

        this.hotlists = vowArray.slice(0, limit);
        this.isLoading = false;

      })
  }

  checkScreen() {
    let innerWidth = window.innerWidth;
    switch (true) {
      case innerWidth <= 340:
        return 1;
      case 341 <= innerWidth && innerWidth <= 480:
        return 2;
      case 481 <= innerWidth && innerWidth <= 720:
        return 3;
      case 721 <= innerWidth && innerWidth <= 960:
        return 4;
      case 961 <= innerWidth:
        return 5;
    }
  }

  showDetail(vow: Vow) {
    if (this.onSale.includes(vow.lsc)) {
      // console.log(`url=${vow.vow_url}`);
      this.router.navigateByUrl(`/property/${vow.vow_url}`);
    } else {
      alert("Property detail no longer available");
    }
    return false;
  }

}
