import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Subscription } from 'rxjs';
import { Website } from './data/website';
import { Login } from './data/login';
import { AuthenService } from './authen/authen.service';
import { WebsiteService } from './data/website.service';
// import { ActivatedRoute, Router } from '@angular/router';
// import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { User } from './data/user';
// import { map } from 'rxjs/operators';
import { Favourite } from './data/favorite';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Brian Tan, Real Estate Agent';
  loginAuth = new Login();
  website: Website;

  private userSub: Subscription;

  public appIndex = 0;
  public appPages = [
    {
      title: 'Home',
      url: '/',
      icon: 'home',
    },
    {
      title: 'Search',
      url: '/search',
      icon: 'search',
    },
    // {
    //   title: 'VOW Listing',
    //   url: '/vow',
    //   icon: 'server',
    // },
    // {
    //   title: 'Blog',
    //   url: '/blog',
    //   icon: 'bookmark',
    // },
    {
      title: 'Contact',
      url: '/contact',
      icon: 'information',
    },
    {
      title: 'Calculator',
      url: '/calculator',
      icon: 'calculator',
    },
    {
      title: 'Market',
      url: '/market/trend',
      icon: 'bar-chart',
    },
    {
      title: 'Lease',
      url: '/lease',
      src: 'assets/icon/lease.svg',
    },
    {
      title: 'Privacy Policy',
      url: '/terms',
      icon: 'document-lock',
    },

  ];

  public settingIndex = 0;
  public settingPages = [
    {
      title: 'CMS',
      url: '/setting/cms',
      icon: 'extension-puzzle',
    },
  ];

  public adminIndex = 0;
  public adminPages = [
    {
      title: 'Profile',
      url: '/admin/profile',
      icon: 'person',
    },
    {
      title: 'Criteria',
      url: '/admin/criteria',
      icon: 'funnel',
    },
    {
      title: 'Condo',
      url: '/admin/condo',
      icon: 'business',
    },
    {
      title: 'Stat Upload',
      url: '/admin/stat-upload',
      icon: 'share',
    },
    // {
    //   title: 'Website',
    //   url: '/admin/website',
    //   icon: 'navigate',
    // },
    {
      title: 'Hotlist',
      url: '/admin/hotlist',
      icon: 'flame',
    },
    {
      title: 'Advert',
      url: '/admin/adv',
      icon: 'pricetag',
    },
    {
      title: 'Drip',
      url: '/admin/drip',
      icon: 'mail',
    },

  ];
  favs: string[] = [];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authenService: AuthenService,
    private websiteService: WebsiteService,
    private route: ActivatedRoute,
    // private router: Router,
    // private location: Location,
    private titleService: Title,


  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.titleService.setTitle(data.title);
    })

    // console.log("app component");

    // console.log(`remove token`);
    localStorage.removeItem('token');
    this.authenService.autoLogin();

    this.userSub = this.authenService.user.subscribe((user: User) => {
      // console.log('get loginAuth');
      this.loginAuth = this.authenService.loginAuth;
    })


    let favStorage = localStorage.getItem('favs');
    if (favStorage) {
      // let favArray = JSON.parse(favStorage);
      // console.log(favArray);
      let tempFavs = [];
      JSON.parse(favStorage).forEach((fav: string | Favourite) => {
        // console.log(fav);
        if (typeof fav === 'object') {
          tempFavs.push(fav.ml_num);
        } else {
          tempFavs.push(fav);
        }
      })
      this.favs = [...new Set(tempFavs)].sort((a, b) => (a < b ? -1 : 1));
    } else {
      this.favs = [];
    }
    // console.log(this.favs);
    localStorage.setItem('favs', JSON.stringify(this.favs));
    // document.getElementById('favicon').setAttribute("href", 'assets/icon/visagehome-icon64.ico');
    // document.getElementById('banner-image').setAttribute("src", "assets/images/banner-toronto-night-skyline-1920x360.jpg");

    this.setWebAttr();
  }

  setWebAttr() {
    let hostName = window.location.hostname;
    let domainName = hostName == '192.168.1.38' ? 'gtahomeindex.com' : hostName.replace(/www./, '');
    // let domainName = "gtahomeindex.com";
    // domainName = domainName.replace(/\./g, '-');
    // console.log(`domain=${domainName}`);
    this.websiteService.fetchWebsite(domainName)
    .subscribe((web: Website) => {
        // console.log(web);
      this.website = Object.assign({}, web);
      // console.log(this.website);
        if (web.web_icon) {
          //  console.log(`setting icon to ${web.web_icon}`);
          document.getElementById('favicon').setAttribute("href", web.web_icon);
        }
        // if (web.web_banner) {
        //   console.log(`setting banner to ${web.web_banner}`);
        //   document.getElementById('banner-image').setAttribute("src", web.web_banner);
        // } else {
        //   document.getElementById('banner-image').setAttribute("src", "../assets/images/banner.jpg");
        // }
        // console.log(web);
        web.web_cell_tr = web.web_cell.replace(/[\(\)-\s]/g, '');
        web.web_email = web.web_email.replace(/@/, " @ ");
        return web;
      })
  }

  ionViewWillEnter() {
    // console.log('app - ionViewWillEnter');
  }
  userLogout() {
    this.authenService.logout();
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }


  // const path = window.location.pathname.split('calculator/')[1];
  // if (path !== undefined) {
  //   this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
  // }
}
