import { formatNumber } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { Vow } from '../../data/vow';

@Component({
  selector: 'app-vow-commerce',
  templateUrl: './vow-commerce.component.html',
  styleUrls: ['./vow-commerce.component.scss']
})
export class VowCommerceComponent implements OnInit {
  @Input() vow: Vow;

  inMetre: boolean = false;
  dim: string = 'ft';
  dim2: string = 'sqft';
  m2f: number = 3.28084;

  financial = [];

  constructor(
  ) { }

  ngOnInit() {
    // console.log(this.vow.vow_rooms)
    this.financial = [
      {
        label: 'Taxes Expense',
        value: formatNumber(this.vow.taxes_exp,'en-ca','0.0-0'),
      },
      {
        label: 'Insurrance',
        value: formatNumber(this.vow.insur,'en-ca','0.0-0'),
      },
      {
        label: 'Management',
        value: formatNumber(this.vow.mgmt,'en-ca','0.0-0'),
      },
      {
        label: 'Maintenance',
        value: formatNumber(this.vow.maint,'en-ca','0.0-0'),
      },
      {
        label: 'Heat Expense',
        value: formatNumber(this.vow.heat_exp,'en-ca','0.0-0'),
      },
      {
        label: 'Hydro Expense',
        value: formatNumber(this.vow.hydro_exp,'en-ca','0.0-0'),
      },
      {
        label: 'Water Expense',
        value: formatNumber(this.vow.water_exp,'en-ca','0.0-0'),
      },
      {
        label: 'Other',
        value: formatNumber(this.vow.other,'en-ca','0.0-0'),
      },
      {
        label: 'Gross Income/Sales',
        value: formatNumber(this.vow.gross_inc,'en-ca','0.0-0'),
      },
      {
        label: '- Vacancy Allowance',
        value: formatNumber(this.vow.vac_perc,'en-ca','0.0-0'),
      },
      {
        label: '- Operating Expenses',
        value: formatNumber(this.vow.oper_exp,'en-ca','0.0-0'),
      },
      {
        label: '= Net Income Before Debt',
        value: formatNumber(this.vow.net_inc,'en-ca','0.0-0'),
      },
      {
        label: 'Est Value Inventory At Cost',
        value: formatNumber(this.vow.inventory,'en-ca','0.0-0'),
      },
      {
        label: 'Com Area Upcharge',
        value: formatNumber(this.vow.com_chgs,'en-ca','0.0-0'),
      },
      {
        label: '% Rent',
        value: formatNumber(this.vow.perc_rent,'en-ca','0.0-0'),
      },
    ];
  }

  toggleShow() {
    this.inMetre = !this.inMetre;
    this.dim = this.inMetre ? 'm' : 'ft';
    this.dim2 = this.inMetre ? 'm²' : 'sqft';
  }

}
