import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError, Observable, of } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthenResponse } from '../authen/authen-response';
import { map, catchError, switchMap, take, exhaustMap } from 'rxjs/operators';

// const ud = 'YWRtaW4KdmlzYWdlaG9tZS5jb20KMjUzMzAyMWgK';
const ud = 'dXNlcgo='+'dmlzYWdlaG9tZS5jb20K'+'dXNlcmNhbnJlYWQK';

@Injectable({
  providedIn: 'root'
})

export class AdminService {
  // admin = new BehaviorSubject<string>(null);

  apiKey = 'AIzaSyAGKQLcHBJMXHYraNO-S8ZbYQfXWA3lyLs';
  firebaseURL = 'https://visage-82084.firebaseio.com';
  signupURL = 'https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=' + this.apiKey;
  signinURL = 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=' + this.apiKey;

  constructor(
    private http: HttpClient,
  ) { }

  adminToken(): Observable<string> {
    let u1 = atob(ud.substr(0,8));
    let d1 = atob(ud.substr(8,20));
    let ic = atob(ud.substr(28,16));
    u1 = u1.substr(0,u1.length-1);
    d1 = d1.substr(0,d1.length-1);
    ic = ic.substr(0,ic.length-1);
 
    // console.log(`adminToken for ${u1}@${d1}/${ic}`);
    let token = JSON.parse(localStorage.getItem('token'));
    // console.log('token='+token);
    if (token) {
      // console.log('token found, return it');
      return of(token);
    } else {
      // console.log('token not found, request new one');
      return this.http.post<AuthenResponse>(`${this.signinURL}`,
        {
          email: `${u1}@${d1}`,
          password: `${ic}`,
          returnSecureToken: true
        }
      ).pipe(
        switchMap((respData: AuthenResponse) => {
          // respData.expiresIn = this.timeOut.toString();

          localStorage.setItem('token', JSON.stringify(respData.idToken));
          // this.admin.next(respData.idToken);
          // setTimeout(this.adminLogin, +respData.expiresIn * 1000 - 60);
          setTimeout(() => {
            localStorage.removeItem('token');
            // console.log('remove token');
          }, +respData.expiresIn * 1000 - 60);
          // }, 30 * 1000);

          return of(respData.idToken);
        }),
        catchError(this.handleError)

      )
    }
  }

  private handleError(errorResp: HttpErrorResponse) {
    let errorMessage = 'An unknown error occured!';
    if (!errorResp.error || !errorResp.error.error) {
      return throwError(errorMessage);
    }
    switch (errorResp.error.error.message) {
      case 'EMAIL_EXISTS':
        errorMessage = 'This email exists already';
        break;
      case 'EMAIL_NOT_FOUND':
      case 'INVALID_PASSWORD':
        errorMessage = "Email or Password not correct";
        break;
      case 'USER_DISABLED':
        errorMessage = "User disabled";
        break;
      case 'TOO_MANY_ATTEMPTS_TRY_LATER':
        errorMessage = "Too many attempts, try again later";
        break;
      default:
        errorMessage = errorResp.error.message;
        break;
    }
    // this.error.next(errorMessage);
    // console.log(errorMessage);
    return throwError(errorMessage);
  }

}
