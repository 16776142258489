import { Location } from '@angular/common';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, Subject, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { api } from '../authen/authen-key';
import { Auth } from './auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  error = new Subject<string>();

  apiKey: string = '';
  // firebaseURL: string = '';
  // signupURL: string = '';
  // signinURL: string = '';
  private firebaseURL = 'https://visage-82084.firebaseio.com';

  constructor(
    private http: HttpClient,
    private location: Location,
    // private authen: LoginComponent,
    private router: Router,
  ) {
    // this.http.get('assets/key.json') //, { responseType: 'json'})
    //   .subscribe((data: api) => {
    //     this.apiKey = data.apiKey;
    //     this.firebaseURL = data.firebaseURL;
    //     this.signupURL = 'https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=' + this.apiKey;
    //     this.signinURL = 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=' + this.apiKey;

    //     // console.log(data);
    //   })
  }

  addAuth(auth:Auth) {
    // console.log(`At addAuth email=${email} id=${id}`);
    auth.role = 'User';
    auth.registered = new Date();
    auth.status = 'New';
    return this.http.put<Auth>(
      `${this.firebaseURL}/auth/${auth.id}.json`,
      auth
    ).subscribe(
      responseData => {
        // console.log(responseData);
        return responseData;
      },
      error => {
        this.error.next(error.message);
      }
    );

  }

  fetchAuth(id: string): Observable<Auth> {
    // console.log(`fetAuth id=${id} `+ new Date().toLocaleString("en-US"));
    return this.http.get<Auth>(
      `${this.firebaseURL}/auth/${id}.json`,
    )
      .pipe(
        map((auth: Auth) => {
          // console.log(`got auth ${id} `+ new Date().toLocaleString("en-US"));
          // console.log(`fetchAuth id=${id}`);
          // this.loginAuth.isAuthenticated = true;
          // this.loginAuth.id = auth.id;
          // this.loginAuth.user = auth.email;
          // this.loginAuth.role = auth.role;
          // if (auth.role=='Admin') {
          //   this.loginAuth.isAdmin = true;
          // }
          return auth;
        }),
        catchError(errorRes => {
          // Send to analytics server
          return throwError(errorRes);
        })
      )
  }

  fetchAuths(): Observable<Auth[]> {
    return this.http.get<Auth[]>(
      `${this.firebaseURL}/auth.json`,
    )
      .pipe(
        tap((auth: Auth[]) => {
          // console.log(auth);
          // this.loginAuth.isAuthenticated = true;
          // this.loginAuth.id = auth.id;
          // this.loginAuth.user = auth.email;
          // this.loginAuth.role = auth.role;
          // if (auth.role=='Admin') {
          //   this.loginAuth.isAdmin = true;
          // }
          // return auth;
        }),
        catchError(errorRes => {
          // Send to analytics server
          return throwError(errorRes);
        })
      )
  }

  fetchAuths_array(count: number): Observable<Auth[]> {
    console.log(`fetchAuths: count=${count}`)
    let url = `${this.firebaseURL}/auth.json`;
    // if (status) url += `?orderBy="status"&equalTo="${status}"`;
    // console.log(url);

    return this.http.get<Auth[]>(url).pipe(
        // tap(_ => this.log(`fetched auths count=${count}`)),
        map((responseData: { [key: number]: Auth }) => {
          console.log('line');
          console.log(responseData);
          let authArray: Auth[] = [];
          // let idx:number = 0;
          for (const key in responseData) {
            // console.log(`key=${key}`);
            if (responseData.hasOwnProperty(key) && responseData[key]) {
              responseData[key].id = key;
              authArray.push({ ...responseData[key] });
            }
          }
          console.log(authArray);
          // sort in desc date order
          // authArray.sort((a, b) => (a.date > b.date) ? -1 : 1);
          authArray.sort((a, b) => (a.email > b.email) ? 1 : -1);
          if (count > 0) {
            authArray = authArray.slice(0, count);
          }
          return authArray;
        }),
        catchError(err => {
          console.log(err);
          this.alert('Danger', `Error fetching auths count=${count}`);
          return throwError(err);
        })
      )
  }

  // deleteAuth() {
  //   return this.http.delete(
  //     `${this.firebaseURL}/auth/-xuajdjdj.json`,
  //     {
  //       observe: 'events',
  //       responseType: 'text' // blog, json
  //     }
  //   )
  //     .pipe(
  //       tap(event => {
  //         // console.log(event);
  //         if (event.type === HttpEventType.Response) {
  //           // console.log(event.body);
  //         }
  //         if (event.type === HttpEventType.Sent) {
  //           // console.log(event.type);
  //           // ui: request sent, wating for response
  //         }
  //       })
  //     )
  // }

  /** DELETE: delete the auth from the server */
  deleteAuth(id: string): Observable<any> {

    return this.http.delete<{ resp: any }>(
      `${this.firebaseURL}/auth/${id}.json`
    ).pipe(

      catchError(err => {
        console.log(err);
        this.alert('Danger', `Error deleting auth ${name}`)
        return throwError(err);
      })
    );
  }

  /** PUT: update the hero on the server */
  // updateAuth(auth: Auth): Observable<any> {
  //   return this.http.put(this.authUrl, auth, httpOptions).pipe(
  //     tap(_ => this.log(`updated auth id=${auth.auth_id}`)),
  //     catchError(this.handleError<any>('updateAuth'))
  //   );
  // }

  updateAuth(auth: Auth): Observable<any> {

    return this.http.put<{ resp: any }>(
      `${this.firebaseURL}/auth/${auth.id}.json`,
      auth
    ).pipe(
      catchError(err => {
        console.log(err);
        this.alert('Danger', `Error updating auth ${auth.email}`)
        return throwError(err);
      })
    )
  }
  /* @param operation - name of the operation that failed
  * @param result - optional value to return as the observable result
  */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a BhffService message with the MessageService  */
  private log(message: string) {
    //    this.logMessage.add(`AuthService: ${message}`);
  }

  private alert(type: string, message: string) {
    //    this.alertMessage.add(type, `AuthService: ${message}`);

  }

}
