import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Vow } from './vow';
// import { LogMessageService } from '../message/log-message/log-message.service';
// import { AlertMessageService } from '../message/alert-message/alert-message.service';
import { Search } from './search';
import { Website } from './website';
import { Hotlist } from './hotlist';

@Injectable({
  providedIn: 'root'
})
export class VowService {

  constructor(
    private http: HttpClient,
//     private logMessage: LogMessageService,
//     private alertMessage: AlertMessageService,
  ) { }

  message = new Subject<string>();
  vow$: Observable<Vow>;
  vows$: Observable<Vow[]>;
  vow: Vow;
  private vowUrl: string =`https://backend.visagehome.com/php/vow.php`;
  error = new Subject<string>();
  private firebaseURL = 'https://visage-82084.firebaseio.com';

  getDomainName() {
    let hostName = window.location.hostname;
    // console.log(`hostname=${this.hostName}`);
    // let domainName = hostName == '192.168.1.38' ? 'visagehome.com' : hostName.replace(/www./,'');
    // this.vowUrl = `https://backend.${domainName}/php/vow.php`;
    this.vowUrl = `https://backend.visagehome.com/php/vow.php`;
    // console.log(`vowUrl = ${this.vowUrl}`);
  }

  getVows(vow_class: string, criteria: string, count: number, status: string): Observable<Vow[]> {

    this.getDomainName();

    let url = `${this.vowUrl}?t=l`;
    // if (vow_class) url += `&class=${vow_class}`;
    if (count) url += `&cnt=${count}`;
    if (criteria) url += `&${criteria}`;
    if (status) url += `&status=${status}`;
    // console.log(url);

    return this.http.get<Vow[]>(url)
      .pipe(
        tap(_ => this.log(`fetched vows criteria=${criteria}`)),
        catchError(this.handleError<Vow[]>('getVows', []))
      );
  }

  getVow(ml_num: string): Observable<Vow> {
    this.getDomainName();
    const url = `${this.vowUrl}?ml_num=${ml_num}`;
    // console.log(url);
    const params = new HttpParams()
      .set('ml_num', `${ml_num}`);
    return this.http.get<Vow>(url)
      .pipe(
        tap(_ => this.log(`fetched vow ml_num=${ml_num}`)),
        catchError(this.handleError<Vow>(`getVow ml_num=${ml_num}`))
      );
  }

  findVow(ml_num: string, muni: string, addr: string, apt: string, role: string): Observable<Vow> {
    this.getDomainName();
    let url = `${this.vowUrl}`;
    if (ml_num) {
      url += `?mls=${ml_num}&role=${role}`;
    } else {
      url += `?role=${role}&muni=${muni}&addr=${addr}&apt=${apt}`;
    }

    // console.log(url);
    return this.http.get<Vow>(url)
      .pipe(
        tap(_ => this.log(`fetched vow ml_num=${ml_num}`)),
        catchError(this.handleError<Vow>(`getVow ml_num=${ml_num}`))
      );
  }

  searchVows(criteria: Search, limit: number): Observable<Vow[]> {
    this.getDomainName();
    let url = `class=${criteria.class}&s_r=${criteria.saleLease}`;
    // console.log(criteria.municipality);
    if (criteria.municipality.search(/toronto/gi) >= 0) {
      url += `&area=toronto`;
    } else {
      if (criteria.municipality) url += `&muni=${criteria.municipality}`;
    }
    if (criteria.keywords) url += `&keywords=${criteria.keywords}`;
    if (criteria.address) url += `&address=${criteria.address}`;
    if (criteria.minPrice) url += `&minprice=${criteria.minPrice}`;
    if (criteria.maxPrice) url += `&maxprice=${criteria.maxPrice}`;
    if (criteria.class == 'COM') {
      if (criteria.business) url += `&business=${criteria.business}`;
      if (criteria.busType) url += `&bus_type=${criteria.busType}`;
    } else {
      if (criteria.minBed) url += `&minbed=${criteria.minBed}`;
      if (criteria.maxBed) url += `&maxbed=${criteria.maxBed}`;
      if (criteria.minWash) url += `&minwash=${criteria.minWash}`;
      if (criteria.maxWash) url += `&maxwash=${criteria.maxWash}`;
    }
    if (limit) url += `&limit=${limit}`;
    // if (page) url += `&page=${page}`;

    // console.log(url);
    url = `${this.vowUrl}?t=search&${url}`
    return this.http.get<Vow[]>(url)
      .pipe(
        tap(_ => this.log(`fetched vows `)),
        catchError(this.handleError<Vow[]>(`getVow s_r=${criteria.saleLease}`))
      );
  }

  getHotlist(hotlist:Hotlist, website:Website, limit: number): Observable<Vow[]> {
    this.getDomainName();
    let url = `s_r=Sale`;
    // console.log(criteria.municipality);
    // url += `&rltr=${website.web_brokerage}`;
    if (website.municipality.search(/toronto/gi) >= 0) {
      url += `&area=toronto`;
    } else {
      if (website.municipality) url += `&muni=${website.municipality}`;
    }
    if (hotlist.featured) url += `&rltr=${website.web_brokerage}`;
    if (hotlist.keywords) url += `&keywords=${hotlist.keywords}`;
    if (hotlist.min_price) url += `&minprice=${hotlist.min_price}`;
    if (hotlist.max_price) url += `&maxprice=${hotlist.max_price}`;
    // if (hotlist.class == 'COM') {
    //   if (hotlist.business) url += `&business=${hotlist.business}`;
    //   if (hotlist.busType) url += `&bus_type=${hotlist.busType}`;
    // } else {
      if (hotlist.min_bed) url += `&minbed=${hotlist.min_bed}`;
      if (hotlist.max_bed) url += `&maxbed=${hotlist.max_bed}`;
      if (hotlist.min_wash) url += `&minwash=${hotlist.min_wash}`;
      if (hotlist.max_wash) url += `&maxwash=${hotlist.max_wash}`;
    // }
    if (limit) url += `&limit=${limit}`;
    // if (page) url += `&page=${page}`;

    // console.log(url);
    url = `${this.vowUrl}?t=hotlist&${url}`
    // console.log(url);
    return this.http.get<Vow[]>(url)
      .pipe(
        tap(_ => this.log(`fetched hotlist `)),
        catchError(this.handleError<Vow[]>(`getHotlist s_r=${website.saleLease}`))
      );
  }

  getFavs(favs: string): Observable<Vow[]> {

    this.getDomainName();

    let url = `${this.vowUrl}?t=favs`;
    if (favs) url += `&favs=${favs}`;
    // console.log(url);

    return this.http.get<Vow[]>(url)
      .pipe(
        tap(_ => this.log(`getFavs favs=${favs}`)),
        catchError(this.handleError<Vow[]>('getFavs', []))
      );
  }

  updateOffer(mls: string, offer: string): Observable<any> {
    this.getDomainName();
    const url = `${this.vowUrl}?t=offer&offer=${offer}&mls=${mls}`;
    return this.http.get<any>(url)
      .pipe(
        tap(_ => console.log(_))
      )
  }

  getCondoSoldComp(ml_num: string): Observable<Vow[]> {
    this.getDomainName();
    let url = `${this.vowUrl}?t=csc&mls=${ml_num}`;
    // console.log(url);
    return this.http.get<Vow[]>(url)
      .pipe(
        tap(_ => this.log(`fetched condo sold comp=${ml_num}`)),
        catchError(this.handleError<Vow[]>('getCondoSoldComp', []))
      );
  }

  getCondoLeasedComp(ml_num: string): Observable<Vow[]> {
    this.getDomainName();
    let url = `${this.vowUrl}?t=clc&mls=${ml_num}`;

    return this.http.get<Vow[]>(url)
      .pipe(
        tap(_ => this.log(`fetched condo leased comp=${ml_num}`)),
        catchError(this.handleError<Vow[]>('getCondoLeasedComp', []))
      );
  }

  getFreeholdSoldComp(ml_num: string): Observable<Vow[]> {
    console.log("getFreeholdSoldComp");
    this.getDomainName();
    let url = `${this.vowUrl}?t=rsc&mls=${ml_num}`;
    // console.log(url);
    return this.http.get<Vow[]>(url)
      .pipe(
        tap(_ => this.log(`fetched freehold sold comp=${ml_num}`)),
        catchError(this.handleError<Vow[]>('getFreeholdSoldComp', []))
      );
  }

  getFreeholdLeasedComp(ml_num: string): Observable<Vow[]> {
    console.log("getFreeholdSoldComp");
    this.getDomainName();
    let url = `${this.vowUrl}?t=rlc&mls=${ml_num}`;

    return this.http.get<Vow[]>(url)
      .pipe(
        tap(_ => this.log(`fetched freehold leased comp=${ml_num}`)),
        catchError(this.handleError<Vow[]>('getFreeholdLeasedComp', []))
      );
  }

  // Common routines

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a BhffService message with the MessageService  */

  private log(message: string) {
//     this.logMessage.add(`VowService: ${message}`);
  }

  private alert(type: string, message: string) {
//     this.alertMessage.add(type, `VowService: ${message}`);

  }


}
