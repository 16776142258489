import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
// import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { ModalController, NavParams, AlertController } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { Age } from 'src/app/data/age';
import { Criteria } from 'src/app/data/criteria';
import { CriteriaService } from 'src/app/data/criteria.service';
import { VowcodeService } from 'src/app/data/vowcode.service';
import { VowCompsComponent } from '../vow-comps/vow-comps.component';

@Component({
  selector: 'app-vow-filter',
  templateUrl: './vow-filter.component.html',
  styleUrls: ['./vow-filter.component.scss'],
})
export class VowFilterComponent implements OnInit {
  @Input() criteria: Criteria;

  constructor(
    private criteriaService: CriteriaService,
    private vowcodeService: VowcodeService,
    // private authenService: AuthenService,
    private route: ActivatedRoute,
    private router: Router,
    // private location: Location,
    // private titleService: Title,
    private modalController: ModalController,
    private navParams: NavParams,
    private alertController: AlertController,
    private formBuilder: FormBuilder,
  ) { }

  criteria$: Observable<Criteria>;
  // criteria: Criteria;

  criteriaForm = this.formBuilder.group({
    id: null,
    name: null,
    class: ['', [Validators.required]],
    saleLease: ['', [Validators.required]],
    email: null,
    send_drip: [''],
    area: ['', [Validators.required]],
    municipality: [''],
    community: [''],
    min_price: [''],
    max_price: ['',[Validators.required]],
    min_maint: [''],
    max_maint: [''],
    type: [''],
    style: [''],
    age: [''],
    keywords: [''],
    min_bed: [''],
    max_bed: [''],
    min_brp: [''],
    max_brp: [''],
    min_wash: [''],
    max_wash: [''],
    min_park: [''],
    max_park: [''],
    last_drip: null,
  })

  isSubmitted = false;
  // loginAuth = new Login();
  // private userSub: Subscription;

  selectedId: string = '';
  // selectedEmail: string = '';
  // loginUser: string;
  mode: string;
  title: string;
  curSegment = 'main';
  criteriaAvail: boolean = false;

  areas$: Observable<string[]>;
  munis$: Observable<string[]>;
  comms$: Observable<string[]>;
  types$: Observable<string[]>;
  styles$: Observable<string[]>;
  ages$: Observable<Age[]>;

  classes: string[] = ["RES", "CND", "COM"];
  className: { [key: string]: string } = {
    "RES": "Freehold",
    "CND": "Condo",
    "COM": "Commercial",
  };

  ngOnInit() {
    // console.log("in criteria-edit component");
    // this.criteria = this.navParams.get('criteria');
    // console.log(this.criteria);
    this.criteriaForm.patchValue(this.criteria);  
    // console.log(this.criteriaForm.value.last_drip);
    // let dmy = formatDate(this.criteria.last_drip,'yyyy-MM-dd HH:mm','en-ca');
    // console.log(dmy);
    // this.criteriaForm.controls.last_drip.setValue(dmy);
    this.criteriaAvail = false;
    // this.userSub = this.authenService.user.subscribe((user: User) => {
    // 	// console.log(user);
    // 	this.loginAuth = this.authenService.loginAuth;
    // })
    // if (this.loginAuth.isAdmin) {
    // 	this.loginUser = 'admin';
    // 	this.selectedEmail = '';
    // } else {
    // 	this.loginUser = this.loginAuth.user;
    // 	this.selectedEmail = this.loginAuth.user;
    // }
    this.criteriaService.message.subscribe((message: string) => {
      // console.log(message);
    })
    // console.log("getarea");
    this.areas$ = this.vowcodeService.getAreas();
    if (this.criteria.area) {
      this.munis$ = this.vowcodeService.getMunis(this.criteria.area);
    }
    if (this.criteria.area && this.criteria.municipality.length==1) {
      this.comms$ = this.vowcodeService.getComms(this.criteria.area, this.criteria.municipality[0]);
    }
    // console.log("gettype");
    this.types$ = this.vowcodeService.getTypes("'RES','CND'");
    // console.log("getstyle");
    this.styles$ = this.vowcodeService.getStyles("'RES','CND'");
    // console.log("getage");
    this.ages$ = this.vowcodeService.getAges();
  }

  ionViewWillEnter() {
    // console.log("ionViewWillEnter");
    // this.titleService.setTitle("Update Criteria");
    // this.getCriteria();
  }

  getDate(e) {
    let date = new Date(e.target.value).toISOString().substring(0,10);
    this.criteriaForm.get('dob').setValue(date, {
      onlyself:true
    })
  }

  get f() {
    return this.criteriaForm.controls;
  }

  submitForm() {
    this.isSubmitted = true;
    if (!this.criteriaForm.valid) {
      console.log('Please provide all the required values!');
      return false;
    }
    // console.log(this.criteriaForm.value);
    this.criteria = this.criteriaForm.getRawValue();
    // console.log(this.criteria);
    this.modalController.dismiss(this.criteria);

  }

  

  initCriteria(): Observable<Criteria> {
    // let hotlist = new Hotlist();
    return of(new Criteria());
  }

  

  segmentChanged(ev: any) {
    // console.log('Segment changed', ev);
    // console.log(ev.detail.value);
    this.curSegment = ev.detail.value;
  }

  // closeModal() {
  //   // if (this.criteria.municipality) {
  //   //   this.criteria.area = null;
  //   // }
  //   // localStorage.setItem('cri', JSON.stringify(this.criteria));
  //   this.modalController.dismiss(this.criteria);
  // }

  async presentAlert(header: string, subHeader: string, msg: string) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: header,
      subHeader: subHeader,
      message: msg,
      buttons: ['OK']
    });
    await alert.present();
  }

  classSelect() {
    // console.log('classSelect()');
    if (this.criteriaForm.controls.class.dirty) {
      // console.log('class dirty');
      let cls = this.criteriaForm.value.class;
      // console.log("on select class=" + cls);
      this.vowcodeService.getTypes(`'${cls}'`).subscribe(res=>{
        // console.log(res);
        this.criteriaForm.controls.type.setValue(res);
      })
      this.criteriaForm.controls.style.setValue(null)
    }
  }

  areaSelect() {
    if (this.criteriaForm.controls.area.dirty) {
      let area = this.criteriaForm.value.area;
      // console.log("on select area=" + area);
      this.munis$ = this.vowcodeService.getMunis(area);
    }
  }

  muniSelect() {
    if (this.criteriaForm.controls.municipality.dirty) {
      let area = this.criteriaForm.value.area;
      let munis = this.criteriaForm.value.municipality;
      // console.log(`on select muni length=${munis.length} area=${area} / ${munis[0]}` );
      if (munis.length == 1) {
        this.comms$ = this.vowcodeService.getComms(area, munis[0]);
        // this.criteria$.forEach(rec=>{
        //   if (rec.municipality != munis) {
        //   rec.community = [];
        //   }
        // })
      } else {
        this.comms$ = of(null);
        // this.criteria$.forEach(rec=>{
        //   console.log("clearing criteria community");
        //   rec.community = [];
        // })
      }
    }
  }

  // initCriteria(): Observable<Criteria> {
  //   let criteria = new Criteria();
  //   criteria.email = this.selectedEmail;
  //   return of(criteria);
  // }

  // addCriteria(criteria: Criteria) {
  //   // criteria.submit = 'Add';
  //   // console.log(criteria);
  //   // console.log("addCriteria title="+criteria.title+"submit="+criteria.submit);

  //   this.criteriaService.addCriteria(criteria)
  //     .subscribe(resp => {
  //       // console.log(resp);
  //     });
  //   this.router.navigate(['/vow']);
  // }

  updateCriteria(criteria: Criteria) {

    if (this.mode == "copy") {
      this.criteriaService.addCriteria(criteria)
        .subscribe(resp => {
          // console.log(resp);
          this.router.navigate(['/vow', resp]);
        });
    } else {
      // blog.submit = 'Add';
      // console.log("updateBlog id="+blog.id);

      this.criteriaService.updateCriteria(criteria)
        .subscribe(resp => {
          // console.log(resp);
          this.router.navigate(['/vow']);
        });
    }
  }

  deleteCriteria(criteria: Criteria) {
    //    criteria.submit = 'Delete';
    // console.log("deleteCriteria id="+criteria.id);

    this.criteriaService.deleteCriteria(criteria.id)
      .subscribe(
        data => {
          // console.log("return status="+data);
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate(['/vow']);
          // this.criteria$.next(criteria);
        },
        error => {
          // console.log("Error", error);
        });
  }

  goBack(name: string): void {
    // this.location.back();
    this.router.navigate(['/vow/criteria', { name: name }]);
  }

}
