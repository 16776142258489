import { formatNumber } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivationEnd } from '@angular/router';
import { Vow } from 'src/app/data/vow';

@Component({
  selector: 'app-vow-feature-home',
  templateUrl: './vow-feature.component.html',
  styleUrls: ['./vow-feature.component.scss'],
})
export class VowFeatureHomeComponent implements OnInit {
  @Input() vow: Vow;

  compass = {
    'N ': "North", 'S ': "South", 'E ': "East", 'W ': "West",
    'Ne': "North East", 'Nw': "North West", 'Se': "South East", 'Sw': "South West"
  }
  frontOn = { 'N': "North", 'S': "South", 'E': "East", 'W': "West" };
  features = [];

  constructor() {
  }

  ngOnInit() {

    let allFeatures = [
      {
        class: ['RES', 'CND'],
        label: 'Size',
        value: this.vow.sqft ? this.vow.sqft + ' sqft' : '-',
      },
      {
        class: ['RES', 'CND'],
        label: 'Age',
        value: this.vow.yr_built ? this.vow.yr_built : '-',
      },
      {
        class: ['CND'],
        label: 'Pets',
        value: this.vow.pets,
      },
      {
        class: ['CND'],
        label: 'Exposure', icon: 'compass',
        value: this.vow.condo_exp ? (this.compass[this.vow.condo_exp] ? this.compass[this.vow.condo_exp] : this.vow.condo_exp) : '-',
        // value: this.vow.condo_exp,
      },
      {
        class: ['RES'],
        label: 'Lot',
        value: formatNumber(this.vow.front_ft,'en-ca','1.0-2') +' x '+ formatNumber(this.vow.depth,'en-ca','1.0-2') + ' feet',
      },
      {
        class: ['RES'],
        label: 'Front on', icon: 'compass',
        value: this.vow.comp_pts ? (this.frontOn[this.vow.comp_pts] ? this.frontOn[this.vow.comp_pts] : this.vow.comp_pts) : '-',
      },
      {
        class: ['RES', 'CND'],
        label: 'Heat', icon: 'thermometer',
        value: this.vow.heating
      },
      {
        class: ['RES', 'CND'],
        label: 'A/C', icon: 'snow',
        value: this.vow.a_c
      },
      {
        class: ['RES', 'CND'],
        label: 'Bedroom', icon: 'bed',
        value: formatNumber(this.vow.br,'en-ca','0.0-0') +
        (this.vow.br_plus>0 ? '+' + formatNumber(this.vow.br_plus,'en-ca','0.0-0') : ''),
      },
      {
        class: ['RES', 'CND'],
        label: 'Washroom', icon: 'water',
        value: formatNumber(this.vow.bath_tot,'en-ca','0.0-0'),
      },
      {
        class: ['RES', 'CND'],
        label: 'Family Room',
        value: this.vow.den_fr,
      },
      {
        class: ['RES', 'CND'],
        label: 'Fireplace', icon: 'flame',
        value: this.vow.fpl_num,
      },
      {
        class: ['RES', 'CND'],
        label: 'Kitchen', icon: 'restaurant',
        value: this.vow.num_kit + (this.vow.kit_plus ? `+${this.vow.kit_plus}` : ''),
      },
      {
        class: ['RES', 'CND'],
        label: 'Laundry', icon: 'shirt',
        value: this.vow.laundry ? this.vow.laundry + ' ' : '' + this.vow.laundry_lev,
      },
      {
        class: ['RES', 'CND'],
        label: 'Parking', icon: 'car',
        value: formatNumber(this.vow.tot_park_spcs,'en-ca','1.0-1'),
      },
      {
        class: ['CND'],
        label: 'Locker', icon: 'key',
        value: this.vow.locker,
      },
      {
        class: ['CND'],
        label: 'Balcony',
        value: this.vow.patio_ter,
      },
      {
        class: ['CND'],
        label: 'Maintenance',
        value: '$'+formatNumber(this.vow.maint,'en-ca','1.2-2'),
      },
      {
        class: ['RES', 'CND'],
        label: 'Cross St', icon: 'navigate',
        value: this.vow.cross_st,
      },

      {
        class: ['RES'],
        label: 'Pool',
        value: this.vow.pool,
      },
      {
        class: ['RES', 'CND'],
        label: 'Retirement',
        value: this.vow.retirement ? this.vow.retirement : '-',
      },
      {
        class: ['CND'],
        label: 'Ammenities',
        textarea: true,
        value: this.vow.vow_amenities,
      },
    ];
    this.features = allFeatures.filter(f => f.class.indexOf(this.vow.vow_class)>=0);
  }



}

