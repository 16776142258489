import { Component, OnInit, Input } from '@angular/core';
import { Setting } from 'src/app/data/setting';
import { SettingService } from 'src/app/data/setting.service';
import { Sold } from '../../data/sold';

@Component({
  selector: 'app-mortgage',
  templateUrl: './vow-mortgage.component.html',
  styleUrls: ['./vow-mortgage.component.scss']
})
export class VowMortgageComponent implements OnInit {
  @Input() sold: Sold;

  price: number;
  maint: number;

  downpct: number = 20;
  down: number;
  int: number;
  year: number = 25;
  freq: string = "m";
  periodic: string = "Monthly";
  numberPayments: number;
  principal: number;
  periodicPayment: number;
  numberYears: number;
  totalPayment: number;
  totalInterest: number;


  constructor(
    private settingService: SettingService,
  ) { }

  ngOnInit() {
    // console.log(this.sold);
    this.settingService.getSetting()
      .subscribe((resp: Setting) => {
        // console.log(resp);
        this.int = resp.interest;
        this.price = this.sold.lp_dol;
        //  this.int = this.sold.int;
        this.maint = this.sold.maint;
        this.calcMortgage();
      })
  }

  calcMortgage() {
    // Yearly interest rate in decimal
    var interestRate = this.int / 100;
    var amortization = this.year;

    switch (this.freq) {
      case "m": // Monthly
        this.periodic = "Monthly";
        var periodsPerMonth = 1;
        var paymentsPerYear = 12;
        this.numberPayments = amortization * 12;
        break;
      case "sm": // Semi-monthly
        this.periodic = "Semi-monthly";
        var periodsPerMonth = 2;
        var paymentsPerYear = 24;
        this.numberPayments = amortization * 24;
        break;
      case "abw": // Bi-weekly accel
        this.periodic = "Bi-weekly Accel";
        var periodsPerMonth = 2;
        var paymentsPerYear = 26;
        this.numberPayments = amortization * 24;
        break;
    }

    this.down = this.price * this.downpct / 100;
    this.principal = this.price - this.down;
    // console.log(`down=${this.down} principal=${this.principal}`)
    // Canadian monthly interest rate (not American)
    var monthlyInterestRate = Math.pow(1 + interestRate / 2, 2 / 12) - 1;
    var effectiveInterestRate = monthlyInterestRate * 12;
    var monthlyPayment = this.principal * monthlyInterestRate
      / (1 - Math.pow(1 + monthlyInterestRate, - amortization * 12));
    this.periodicPayment = monthlyPayment / periodsPerMonth;
    var period = - Math.log(1 - this.principal / this.periodicPayment
      * effectiveInterestRate / paymentsPerYear)
      / Math.log(1 + effectiveInterestRate / paymentsPerYear);
    this.numberYears = period / paymentsPerYear;
    this.totalPayment = this.periodicPayment * period;
    this.totalInterest = this.totalPayment - this.principal;

    // Round numbers
    this.principal = Math.round(this.principal * 100) / 100;
    this.numberYears = Math.round(this.numberYears * 10) / 10;
    this.periodicPayment = Math.round(this.periodicPayment * 100) / 100;
    this.totalPayment = Math.round(this.totalPayment * 100) / 100;
    this.totalInterest = Math.round(this.totalInterest * 100) / 100;

  }

  // upDown() {
  //   this.down = this.price * this.downpct / 100;
  //   this.calcMortgage();
  // }

  // upPct() {
  //   this.downpct = this.down / this.price * 100;
  //   this.calcMortgage();
  // }

}
