import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable, throwError, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { Hotlist } from './hotlist';

@Injectable({
  providedIn: 'root'
})
export class HotlistService {

  constructor(
    private http: HttpClient,
    //     private logMessage: LogMessageService,
    //     private alertMessage: AlertMessageService,
    // private myService: MessagesService,
  ) { }

  Category: string;
  message = new Subject<string>();

  // private hotlistUrl = 'https://backend.visagehome.com/php/hotlist.php';
  error = new Subject<string>();
  private firebaseURL = 'https://visage-82084.firebaseio.com';
  // private postUrl = 'https://admin.visagehome.com/php/getpost.php';


  fetchHotlists(count: number): Observable<Hotlist[]> {
    // console.log(`fetchHotlists: count=${count} `);
    let url = `${this.firebaseURL}/hotlist.json`;
    // if (status) url += `?orderBy="status"&equalTo="${status}"`;

    return this.http.get<Hotlist[]>(url).pipe(
      // tap(_ => this.log(`fetched hotlists count=${count} `)),
      map((responseData: { [key: number]: Hotlist }) => {
        // console.log('line');
        // console.log(responseData);
        let hotlistArray: Hotlist[] = [];
        // let idx:number = 0;
        for (const key in responseData) {
          // console.log(`key=${key}`);
          if (responseData.hasOwnProperty(key) && responseData[key]) {
            responseData[key].id = key;
            hotlistArray.push({ ...responseData[key] });
          }
        }
        // console.log(hotlistArray);
        // sort in desc date order
        // hotlistArray.sort((a, b) => (a.date > b.date) ? -1 : 1);
        hotlistArray.sort((a, b) => (a.name > b.name) ? 1 : -1);
        if (count) {
          hotlistArray = hotlistArray.slice(0, count);
        }
        return hotlistArray;
      }),
      catchError(err => {
        console.log(err);
        this.alert('Danger', `Error fetching hotlists count=${count}`);
        return throwError(err);
      })
    )
  }

  fetchHotlist(id: string): Observable<Hotlist> {
    // let searchParams = new HttpParams();
    // searchParams = searchParams
    //   .append('print', 'pretty')
    //   // .append('custom','key')
    //   ;
    // console.log("fetchHotlist name=" + name);
    let url = `${this.firebaseURL}/hotlist/${id}.json`;
    // console.log(url);
    return this.http.get<Hotlist>(url).pipe(
      map(resp => {
        // console.log(resp);
        if (resp == null) {
          return new Hotlist();
        }
        if (resp) {
          resp.id = id;
        }
        return resp;
      }),
      catchError(err => {
        // Send to analytics server
        console.log(err);
        this.alert('Danger', `Error fetching hotlist id=${id}`);
        return throwError(err);
      })
    )
  }

  /** Put: add a new hotlist to the server */
  addHotlist(hotlist: Hotlist): Observable<string> {
    let id = this.genId(hotlist.name);
    // console.log(`id=${id}`);
    return this.http.put<{ resp: any }>(
      `${this.firebaseURL}/hotlist/${id}.json`, hotlist).pipe(
        map(resp => {
          // console.log(resp);
          return id;
        }),
        catchError(err => {
          console.log(err);
          this.alert('Danger', `Error adding hotlist ${id}`)
          return throwError(err);
        })
      )
    // catchError(_ =>{})
  }

  /** POST: add a new hotlist to the server */
  addHotlistPost(hotlist: Hotlist): Observable<any> {
    // let key = form.frm_id;
    // let postData = { [form.frm_id] : form } ;

    // hotlist.last_drip = new Date(now.valueOf() - 864E5*90);
    // console.log(hotlist);
    return this.http
      .post<{ resp: any }>(
        `${this.firebaseURL}/hotlist.json`,
        hotlist)
      .pipe(
        map(resp => {
          console.log(resp);
          for (const key in resp) {
            let id: string = resp[key];
            console.log(`hotlist id=${id}`);
          }
          return resp;
        }),
        catchError(err => {
          console.log(err);
          this.alert('Danger', `Error adding hotlist ${hotlist.name}`)
          return throwError(err);
        })
      )
    // catchError(_ =>{})
  }

  /** DELETE: delete the hotlist from the server */
  deleteHotlist(id: string): Observable<any> {
    return this.http.delete<{ resp: any }>(
      `${this.firebaseURL}/hotlist/${id}.json`
    ).pipe(

      catchError(err => {
        console.log(err);
        this.alert('Danger', `Error deleting hotlist ${name}`)
        return throwError(err);
      })
    );
  }

  genId(name: string): string {
    return name.replace(/[\/ ,\.@:]/g, '-').replace(/--/g, '-');
  }

  updateHotlist(hotlist: Hotlist): Observable<string> {
    let id = this.genId(hotlist.name);
    let old_id = hotlist.id;
    hotlist.id = null;
    return this.http.put<{ resp: any }>(
      `${this.firebaseURL}/hotlist/${id}.json`,
      hotlist
    ).pipe(
      map(_ => {
        if (id != old_id) {
          // console.log(`delete old record ${old_id}`);
          this.http.delete<{ resp: any }>(
            `${this.firebaseURL}/hotlist/${old_id}.json`)
            .subscribe()
          // catchError(err => {
          //   console.log(err);
          //   this.alert('Danger', `Error deleting old record ${hotlist.old_id}`)
          //   return throwError(err);
          // })

        }
        return id;
      }),
      catchError(err => {
        console.log(err);
        this.alert('Danger', `Error deleting old record ${old_id}`)
        return throwError(err);
      })
    )
  }

  /* @param operation - name of the operation that failed
  * @param result - optional value to return as the observable result
  */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a BhffService message with the MessageService  */
  private log(message: string) {
    //    this.logMessage.add(`HotlistService: ${message}`);
  }

  private alert(type: string, message: string) {
    //    this.alertMessage.add(type, `HotlistService: ${message}`);

  }

}

