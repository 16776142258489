import { Location } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Auth } from '../data/auth';
import { AuthenResponse } from './authen-response';
import { Login } from '../data/login';
import { User } from '../data/user';
import { api } from './authen-key';
import { AuthService } from '../data/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenService {
  user = new BehaviorSubject<User>(null);

  loginAuth = new Login();

  // timeOut = 90 * 86400;

  redirectUrl: string;

  private tokenExpirationTimer: any;

  // Document:
  // https://firebase.google.com/docs/reference/rest/auth

  apiKey: string = '';
  firebaseURL: string = '';
  signupURL: string = '';
  signinURL: string = '';
  resetPasswordURL: string = '';
  // authArray: Auth[] = [];

  constructor(
    private http: HttpClient,
    private location: Location,
    // private authen: LoginComponent,
    private authService: AuthService,
    private router: Router,

  ) {
    this.http.get('assets/key.json') //, { responseType: 'json'})
      .subscribe((data: api) => {
        this.apiKey = data.apiKey;
        this.firebaseURL = data.firebaseURL;
        this.signupURL = 'https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=' + this.apiKey;
        this.signinURL = 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=' + this.apiKey;
        this.resetPasswordURL = 'https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=' + this.apiKey;

        // console.log(data);
      })
  }

  signup(auth: Auth, password: string) {
    // console.log(`email=${email} password=${password}`);
    return this.http.post<AuthenResponse>(`${this.signupURL}`,
      {
        email: auth.email,
        password: password,
        returnSecureToken: true
      }
    ).pipe(
      catchError(this.handleError),
      tap((respData: AuthenResponse) => {
        // console.log(respData);
        // respData.expiresIn = this.timeOut.toString();
        // auth.email = respData.email;
        auth.id = respData.localId
        this.authService.addAuth(auth);
        this.redirectUrl = '/vow';
        alert("Registration successful. Click OK to continue");
        this.handleAuthentication(respData);
      })
    );
  }

  login(email: string, password: string) {
    // console.log(`email=${email} password=${password}`);
    return this.http.post<AuthenResponse>(`${this.signinURL}`,
      {
        email: email,
        password: password,
        returnSecureToken: true
      }
    ).pipe(
      catchError(this.handleError),
      tap((respData: AuthenResponse) => {
        // respData.expiresIn = this.timeOut.toString();
        // console.log(respData);

        this.handleAuthentication(respData);
      })
    );
  }

  resetPassword(email: string) {
    // console.log(`email=${email} password=${password}`);
    return this.http.post<AuthenResponse>(`${this.resetPasswordURL}`,
      {
        requestType: "PASSWORD_RESET",
        email: email,
      }
    ).pipe(
      catchError(this.handleError),
    );
  }

  private handleAuthentication(respData: AuthenResponse) {
    const expirationDate = new Date(
      new Date().getTime() + +respData.expiresIn * 1000
      // new Date().getTime() + this.timeOut * 1000
    );
    this.authService.fetchAuth(respData.localId).subscribe((auth: Auth) => {
      const user = new User(respData.email, respData.localId, auth.firstName, auth.role, respData.idToken, expirationDate);
      this.loginAuth.isAuthenticated = true;
      this.loginAuth.id = user.id;
      this.loginAuth.user = user.email;
      this.loginAuth.name = user.name;
      this.loginAuth.role = user.role;
      this.loginAuth.isAdmin = user.role == 'Admin';
      // // console.log("login/signup to get authen");
      // this.fetchAuth(user.id).subscribe((authen:Authen)=> { 
      // console.log("login/signup got authen");
      //   this.authen.next(authen); 
      //   this.isAdmin = authen.role == 'Admin' ? true : false;
      // });
      this.user.next(user);
      this.autoLogout(+respData.expiresIn * 1000)
      // this.autoLogout(this.timeOut * 1000);
      localStorage.setItem('userData', JSON.stringify(user));
      if (this.redirectUrl) {
        this.router.navigateByUrl(this.redirectUrl);

      } else {
        this.router.navigateByUrl('/vow');
      }
    })
    // console.log(`handleAuthentication timeOut=${this.timeOut} ${respData.email} ${respData.localId} ${respData.idToken} ${expirationDate}`);

  }

  private handleError(errorResp: HttpErrorResponse) {
    let errorMessage = 'An unknown error occured!';
    if (!errorResp.error || !errorResp.error.error) {
      return throwError(errorMessage);
    }
    switch (errorResp.error.error.message) {
      case 'EMAIL_EXISTS':
        errorMessage = 'This email already exists';
        break;
      case 'EMAIL_NOT_FOUND':
      case 'INVALID_PASSWORD':
        errorMessage = "Email or Password not correct";
        break;
      case 'USER_DISABLED':
        errorMessage = "User disabled";
        break;
      case 'TOO_MANY_ATTEMPTS_TRY_LATER':
        errorMessage = "Too many attempts, try again later";
        break;
      default:
        errorMessage = errorResp.error.error.message;
        break;
    }
    // this.error.next(errorMessage);
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  autoLogin() {
    // console.log("at autoLogin "+ new Date().toLocaleString("en-US")); 
    const userData: {
      email: string;
      id: string;
      name: string;
      role: string;
      _token: string;
      _tokenExpirationDate: string;
    } = JSON.parse(localStorage.getItem('userData'));
    if (!userData) {
      return;
    }
    const loadedUser = new User(
      userData.email,
      userData.id,
      userData.name,
      userData.role,
      userData._token,
      new Date(userData._tokenExpirationDate),
    );

    if (loadedUser.token) {
      // console.log('got user token '+ new Date().toLocaleString("en-US"));
      this.loginAuth.isAuthenticated = true;
      this.loginAuth.id = loadedUser.id;
      this.loginAuth.user = loadedUser.email;
      this.loginAuth.name = loadedUser.name;
      this.loginAuth.role = loadedUser.role;
      this.loginAuth.isAdmin = loadedUser.role == 'Admin';
      this.user.next(loadedUser);
      // this.fetchAuth(loadedUser.id).subscribe((auth:Auth)=>{
      // console.log(auth)
      // })

      const expirationDuration
        = new Date(userData._tokenExpirationDate).getTime()
        - new Date().getTime();
      this.autoLogout(expirationDuration);
    }
  }

  logout() {
    // console.log('logout '+ new Date().toLocaleString("en-US"));
    this.loginAuth.isAuthenticated = false;
    this.loginAuth.id = '';
    this.loginAuth.user = '';
    this.loginAuth.role = '';
    this.loginAuth.isAdmin = false;
    this.user.next(null);
    // this.initLogin();

    // this.authen.next(null);
    // this.router.navigate(['/login']);
    localStorage.removeItem('userData');
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
    this.tokenExpirationTimer = null;
    // this.router.navigate(['/login']);
    this.redirectUrl = this.location.path();
    this.router.navigateByUrl('/login');
  }

  autoLogout(expirationDuration: number) {
    // console.log(expirationDuration)
    this.tokenExpirationTimer = setTimeout(() => {
      this.logout();
    }, expirationDuration);
  }

  userIsAdmin(id: string): Observable<boolean> {
    // console.log(`userIsAdmin ${id} called`);
    return this.authService.fetchAuth(id).pipe(
      map((auth: Auth) => {
        // console.log(responseData);
        return auth.role == 'Admin';
      }),
      catchError(errorRes => {
        // Send to analytics server
        return throwError(errorRes);
      })
    )
  }

  userRole(id: string): Observable<string> {
    // console.log(`userIsAdmin ${id} called`);
    return this.authService.fetchAuth(id).pipe(
      map((auth: Auth) => {
        return auth.role;
      }),
      catchError(errorRes => {
        // Send to analytics server
        return throwError(errorRes);
      })
    )
  }

}
