import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
// import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { MessageModule } from './message/message.module';
import { SharedModule } from './shared/shared.module';
// import { CookieService } from 'ngx-cookie-service';
import { AuthenInterceptorService } from './authen/authen-interceptor.service';
// import { AuthenComponent } from './authen/authen.component.ts.z';
// import { CommonModule } from '@angular/common';
// import { LoginComponent } from './shared/login/login.component';


@NgModule({
  declarations: [
    AppComponent,
    // AuthenComponent,
  ],
  entryComponents: [],
  imports: [
    // CommonModule,
    BrowserModule,
    // FormsModule,
    HttpClientModule,
    // MessageModule,
    SharedModule,
    // LoginComponent,

    IonicModule.forRoot(),
    AppRoutingModule
  ],
  providers: [
    // CookieService,
    // Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenInterceptorService,
      multi: true,
    },
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
