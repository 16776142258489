import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthenGuard } from './authen/authen.guard';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: '/search',
  //   pathMatch: 'full'
  // },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'blog',
    loadChildren: () => import('./blog/blog.module').then( m => m.BlogPageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./vow/vow.module').then( m => m.VowPageModule),
    canLoad: [AuthenGuard],
  },
  {
    path: 'property',
    loadChildren: () => import('./property/property.module').then( m => m.PropertyPageModule)
  },
  
  {
    path: 'calculator',
    data: { Title: 'Calculator' },
    loadChildren: () => import('./calculator/calculator.module').then( m => m.CalculatorPageModule)
  },
  {
    path: 'calculator/:sub',
    data: { Title: 'Calculator' },
    loadChildren: () => import('./calculator/calculator.module').then( m => m.CalculatorPageModule)
  },
  {
    path: 'market',
    data: { Title: 'Market Stats' },
    loadChildren: () => import('./market/market.module').then( m => m.MarketPageModule)
  },
  {
    path: 'market/:sub',
    data: { Title: 'Market Stats' },
    loadChildren: () => import('./market/market.module').then( m => m.MarketPageModule)
  },
  {
    path: 'lease',
    data: { Title: 'Lease / Rental' },
    loadChildren: () => import('./lease/lease.module').then( m => m.LeasePageModule)
  },
  {
    path: 'lease/:sub',
    data: { Title: 'Lease / Rental' },
    loadChildren: () => import('./lease/lease.module').then( m => m.LeasePageModule)
  },
  {
    path: 'lease/:sub',
    redirectTo: 'lease'
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then( m => m.AdminPageModule),
    canLoad: [AuthenGuard]
  },
  {
    path: 'setting',
    loadChildren: () => import('./setting/setting.module').then( m => m.SettingPageModule),
    canLoad: [AuthenGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule),
    canLoad: [AuthenGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'vow',
    redirectTo: 'search',
    // loadChildren: () => import('./vow/vow.module').then( m => m.VowPageModule),
    // canLoad: [AuthenGuard]
  },
  {
    path: 'photo',
    loadChildren: () => import('./photo/photo.module').then( m => m.PhotoPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'criteria',
    loadChildren: () => import('./vow/vow-criteria/vow-criteria.module').then( m => m.VowCriteriaPageModule),
    canLoad: [AuthenGuard]
  },
  {
    path: 'about',
    redirectTo: 'contact'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'terms/:sub',
    redirectTo: 'terms'
  },
  {
    path: 'privacy-policy',
    redirectTo: 'terms'
  },
  {
    path: 'terms-and-conditions',
    redirectTo: 'terms'
  },
  
  {
    path: '**',
    loadChildren: () => import('./page-not-found/page-not-found.module').then( m => m.PageNotFoundPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
