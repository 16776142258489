import { Component, OnInit, Input } from '@angular/core';
import { Vow } from '../../data/vow';

@Component({
  selector: 'app-vow-rooms',
  templateUrl: './vow-rooms.component.html',
  styleUrls: ['./vow-rooms.component.scss']
})
export class VowRoomsComponent implements OnInit {
  @Input() vow: Vow;

  inMetre: boolean = false;
  dim: string = 'ft';
  dim2: string = 'ft²';
  m2f: number = 3.28084;

  constructor(
  ) { }

  ngOnInit(): void {
    // console.log(this.vow.vow_rooms)
  }

  toggleShow() {
    this.inMetre = !this.inMetre;
    this.dim = this.inMetre ? 'm' : 'ft';
    this.dim2 = this.inMetre ? 'm²' : 'ft²';
  }

  checkScreen() {
    let innerWidth = window.innerWidth;
    switch (true) {
      case innerWidth <= 650:
        return 1;
      default:
        return 2;
    }
  }
}
