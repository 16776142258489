export class Criteria {
  id: number = null;
  name: string = null;
  username: string = null;
  class: string[] = ['RES','CND'];
  sale_lease: string = 'Sale';
  type: string[] = ['Detached','Semi-Detached','Link','Att/Row/Twnhouse'];
  style: string[] = null;
  bus_type: string[] = null;
  prop_type: string[] = null;
  area: string = 'Toronto';
  municipality: string[] = null;
  community: string[] = null;
  age: string[] = null;
  keywords: string = null;
  locker: string[] = null;
  min_price: number = 600000;
  max_price: number = 1000000;
  min_bed: number = 3;
  max_bed: number = null;
  min_brp: number = null;
  max_brp: number = null;
  min_bath: number = 3;
  max_bath: number = null;
  min_park: number = null;
  max_park: number = null;
  min_maint: number = null;
  max_maint: number = null;
  min_dom: number = null;
  max_dom: number = null;
  email: string = null;
  send_drip: number = null;
  last_drip: Date = null;
  title: string = null;
  color: string = null;
  background: string = null;
}


export interface pgCriteria {
  cri_id: number;
  cri_con_id: string;
  cri_name: string;
  cri_title: string;
  cri_lang: string;
  cri_banner: string;
  cri_area: string;
  cri_municipal: string;
  cri_community: string;
  cri_mls_no: string;
  cri_type: string;
  cri_style: string;
  cri_min_price: string;
  cri_max_price: string;
  cri_min_bed: string;
  cri_max_bed: string;
  cri_min_wash: string;
  cri_max_wash: string;
  cri_dom: number;
  cri_min_dom: number;
  cri_max_dom: number;
  cri_list: string;
  cri_flag: string;
  cri_user: string;
  cri_ip: string;
  cri_trx_date: Date;
  submit: string;
}
