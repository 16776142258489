import { formatNumber } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { AuthenService } from 'src/app/authen/authen.service';
import { Favourite } from 'src/app/data/favorite';
import { Login } from 'src/app/data/login';
import { User } from 'src/app/data/user';
// import { Setting } from 'src/app/data/setting';
// import { SettingService } from 'src/app/data/setting.service';
import { Vow } from 'src/app/data/vow';

@Component({
  selector: 'app-vow-main',
  templateUrl: './vow-main.component.html',
  styleUrls: ['./vow-main.component.scss'],
})
export class VowMainComponent implements OnInit {
  @Input() vow: Vow;
  @Input() hidden: boolean;

  loginAuth = new Login();
	private userSub: Subscription;

  maint = [];
  // favs: string[];
  // inCart: boolean = false;

  onSale = ['New', 'Pc ', 'Sc '];
  sold = ['Sld','Lsd'];
  lastStatus: { [key: string]: string } = {
		"New": "New",
		"Pc ": "Price Changed",
    "Ext": "Extended",
    "Sc ": "Cond. Sold",
    "Sld": "Sold",
    "Lc ": "Cond. Leased",
    "Lsd": "Leased",
    "Ter": "Terminated",
    "Sus": "Suspended",
	};
  columns: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastController: ToastController,
    private authenService: AuthenService,
  ) { }

  ngOnInit() {
    // console.log(`mls=${this.vow.ml_num} hidden=${this.hidden}`);
    // let favStorage = localStorage.getItem('favs');
    // if (favStorage) {
    //   this.favs = JSON.parse(favStorage);
    // } else {
    //   this.favs = [];
    // }
    // this.inCart = this.favs.indexOf(this.vow.ml_num) > -1 ? true : false;
    this.userSub = this.authenService.user.subscribe((user: User) => {
			// console.log(user);
			this.loginAuth = this.authenService.loginAuth;
		})
    this.columns = this.checkScreen();
    let tot_park_spcs = formatNumber(this.vow.tot_park_spcs, 'en-ca', '1.0-1');
    this.maint = [
      {
        color: this.vow.water_inc == 'Y' ? 'success' : 'danger',
        src: '../../../assets/icon/tap-water.svg', prompt: 'Water', msg: 'Water included if Green',
      },
      {
        color: this.vow.hydro_inc == 'Y' ? 'success' : 'danger',
        src: '../../../assets/icon/power-socket.svg', prompt: 'Hydro', msg: 'Hydro included if Green',
      },
      {
        color: this.vow.heat_inc == 'Y' ? 'success' : 'danger',
        src: '../../../assets/icon/heat-wave.svg', prompt: 'Heat', msg: 'Heat included if Green',
      },
      {
        color: this.vow.cac_inc == 'Y' ? 'success' : 'danger',
        icon: 'snow', prompt: 'A/C', msg: 'A/C included if Green',
      },
      {
        color: this.vow.cable == 'Y' ? 'success' : 'danger',
        src: '../../../assets/icon/cable-tv.svg', prompt: 'Cable TV', msg: 'Cable TV included if Green',
      },
      {
        color: this.vow.tot_park_spcs > 0 ? 'success': 'danger',
        src: `../../../assets/icon/parking-space-${tot_park_spcs}.svg`,
        msg: 'Number of parking space', // text: tot_park_spcs,
      },
      {
        color: this.vow.locker == "None"||this.vow.locker=="Ensuite" ? 'danger' : 'success',
        src: '../../../assets/icon/cage-key.svg',
        msg: 'Locker',  // text: this.vow.locker != "None" ? this.vow.locker : '',
      },
    ]

  }

  async presentToast(msg:string, dur: number) {
    // console.log(`present toast ${msg}`);
    const toast = await this.toastController.create({
      color: "tertiary",
      position: "bottom",
      message: msg,
      duration: dur
    });
    toast.present();
  }

  checkScreen() {
    let innerWidth = window.innerWidth;
    switch (true) {
      case 340 <= innerWidth && innerWidth <= 500:
        return 1;
        case 501 <= innerWidth && innerWidth <= 900:
        return 2;
        case 901 <= innerWidth:
        return 3;
    }
  }

  // showDetail(vow: Vow) {
  //   if (this.onSale.includes(vow.lsc)) {
  //     this.router.navigateByUrl(`/property/${vow.vow_url}`);
  //   }
  //   return false;
  // }

  // console.log("add cart for mls " + mls);

  // toggleCart(mls: string) {
  //   console.log("add cart for mls " + mls);
  //   let index = this.favs.indexOf(mls);
  //   if (index > -1 ) {
  //     this.favs.splice(index,1);
  //   } else {
  //     this.favs.push(mls);
  //   }
  //   localStorage.setItem('favs', JSON.stringify(this.favs));
  //   return true;
  // }

}
