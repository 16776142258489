import { Injectable } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { Setting } from './setting';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map, tap, switchMap, exhaustMap } from 'rxjs/operators';
// import { LogMessageService } from '../message/log-message/log-message.service';
// import { AlertMessageService } from '../message/alert-message/alert-message.service';

@Injectable({
  providedIn: 'root'
})

export class SettingService {

  constructor(
    private http: HttpClient,
//     private logMessage: LogMessageService,
//     private alertMessage: AlertMessageService,
    // private myService: MessagesService,
  ) { }

  Category: string;
  message = new Subject<string>();

  private settingUrl : string;
  error = new Subject<string>();
  private firebaseURL = 'https://visage-82084.firebaseio.com';
  // private postUrl = 'https://admin.visagehome.com/php/getpost.php';

  getDomainName() {
    // let hostName = window.location.hostname;
    // // console.log(`hostname=${this.hostName}`);
    // let domainName = hostName == '192.168.1.38' ? 'visagehome.com' : hostName.replace(/www./,'');
    // this.settingUrl = `https://backend.${domainName}/php/setting.php`;
    this.settingUrl = `https://backend.visagehome.com/php/setting.php`;
    // console.log(`vowUrl = ${this.vowUrl}`);
  }

  /** GET hero by id. Will 404 if id not found */

  getSetting(): Observable<Setting> {
    this.getDomainName();
    let url = `${this.settingUrl}`;
    // console.log(url);
    return this.http.get<Setting>(url).pipe(
        tap(_ => {
          // console.log(_);
          this.log(`getSetting `);
        }),
        catchError(this.handleError<Setting>('getSetting'))
      );
  }

  fetchSetting(name: string): Observable<Setting> {
    // let searchParams = new HttpParams();
    // searchParams = searchParams
    //   .append('print', 'pretty')
    //   // .append('custom','key')
    //   ;

    return this.http
      .get<Setting>(`${this.firebaseURL}/setting.json`).pipe(
        map(responseData => {
          // console.log(responseData);
          return responseData;
        }),
        catchError(err => {
          // Send to analytics server
          console.log(err);
          this.alert('Danger', `Error fetching setting`);
          return throwError(err);
        })
      )
  }

  /* @param operation - name of the operation that failed
  * @param result - optional value to return as the observable result
  */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a BhffService message with the MessageService  */
  private log(message: string) {
//     this.logMessage.add(`AreaService: ${message}`);
  }

  private alert(type: string, message: string) {
//     this.alertMessage.add(type, `AreaService: ${message}`);

  }

}
