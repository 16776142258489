import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment,  UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenService } from './authen.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenGuard implements CanLoad {
  constructor(
    private authenService: AuthenService,
    private router: Router,
    private location: Location,
  ) { }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree>
    | boolean | UrlTree {
    let url: string = this.location.path();
    // console.log("url=" + url);
    // console.log("isauthen=" + this.authenService.loginAuth.isAuthenticated);

    if (!this.authenService.loginAuth.isAuthenticated) {
      this.authenService.redirectUrl = url;
      // console.log("set redirectURL to " + this.authenService.redirectUrl);

      
      // console.log("rediect to login");
      return this.router.createUrlTree(['/login']);
    }

    // return this.authenService.loginAuth.isAuthenticated;
    return true;
  }
}
