import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
// import { AuthenService } from '../../data/authen.service';
// import { Subscription } from 'rxjs';
// import { Auth } from './auth';
import { AuthenResponse } from '../../authen/authen-response';
import { Observable, Subscription } from 'rxjs';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
// import { switchMap, take, map, exhaustMap } from 'rxjs/operators';
// import { Path } from './path';
import { Title } from '@angular/platform-browser';
// import { Login } from '../../data/login';
import { Auth } from '../../data/auth';
import { AuthService } from 'src/app/data/auth.service';
import { AuthenService } from '../../authen/authen.service';
// import { AuthenService } from '../../data/authen.service';
// import { AuthenService } from 'projects/visage/src/app/authen/authen.service';


@Component({
  selector: 'lib-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  @Output() userId = new EventEmitter<string>();

  // loadedAuth: Authen[] = [];
  isLoginMode = true;
  isLoading = false;

  error:string = null;
  
  // private errorSub: Subscription;
  // path$: Observable<Path>;
  // private userSub: Subscription;
  // user: User;
  // public userIdSub = new Subject<string>();


  // firebaseURL = "https://visage-82084.firebaseio.com/"

  constructor(
    // private http: HttpClient,
    private authenService: AuthenService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
  ) { }


  ngOnInit() {
    // console.log("In login component");

    // this.authenService.initLogin();
  }
  
  onSwitchMode() {
    this.isLoginMode = !this.isLoginMode;
  }

  onSubmit(form: NgForm) {

    // console.log(form.value);
    if (!form.valid) {
      return;
    }
    const email = form.value.email;
    const password = form.value.password;

    let authObs: Observable<AuthenResponse>;

    this.isLoading = true;
    
    if (this.isLoginMode) {
      authObs = this.authenService.login(email, password);
    // } else {
    //   authObs = this.authenService.signup(email, password, 'First Name', 'Last Name');
    }
    authObs.subscribe((respData: AuthenResponse) => {
      // console.log(respData);
      this.isLoading = false;
      // console.log(window.location);
      this.authService.fetchAuth(respData.localId)
      .subscribe((auth:Auth) => {
        // console.log(auth);
      })
      // this.user.email = respData.email;
      // this.user.id = respData.localId;
      this.userId.emit(respData.localId)
      // this.userIdSub.next(respData.localId);
    },
    errorMessage => {
      // console.log(errorMessage);
      this.error = errorMessage;
      this.isLoading = false;
    });

    // this.onCreateAuth(form.value)
    form.reset();
  }
}