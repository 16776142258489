export class Website {
	web_id: number = null;
	web_name: string = null;
	web_brand: string = null;
	web_banner: string = null;
	web_team: string = null;
	web_realtor: string = null;
	web_designation: string = null;
	web_brokerage: string = null;
	web_email: string = null;
	web_office: string = null;
	web_direct: string = null;
	web_cell: string = null;
	web_cell_tr: string = null;
	web_flag: string = null;
	web_user: string = null;
	web_ip: string = null;
	web_trx_date: Date = null;
	web_slogan: string = null;
	web_icon: string = null;
	web_lang: string = null;
	web_photo: number = null;
	web_domain: string = null;
	web_facebook: string = null;
	web_twitter: string = null;
	web_ads: string = null;
	class: string = null;
	saleLease: string = null;
	municipality: string = null;
	submit: string = null;
}
