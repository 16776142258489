import { Injectable } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { Criteria } from './criteria';
import { WP_Users } from './wp_users';
// import { MessagesService } from '../messages/messages.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map, tap, switchMap, exhaustMap } from 'rxjs/operators';
// import { LogMessageService } from '../message/log-message/log-message.service';
// import { AlertMessageService } from '../message/alert-message/alert-message.service';
// import { LogMessageService } from '../message/log-message.service';
// import { AlertMessageService } from '../message/alert-message.service';
// import { AlertMessageService } from 'projects/alert-message/src/public-api';
// import { LogMessageService } from 'projects/log-message/src/public-api';
// import { Codes } from './codes';
// import { WP_Posts } from './wp_posts';

const httpOptions = {
  headers: new HttpHeaders(
    { 'Content-type': 'application/json' }
  )
};

@Injectable({
  providedIn: 'root'
})

export class CriteriaService {

  constructor(
    private http: HttpClient,
    //     private logMessage: LogMessageService,
    //     private alertMessage: AlertMessageService,
    // private myService: MessagesService,
  ) { }

  Category: string;
  message = new Subject<string>();
  className: { [key: string]: string } = {
		"RES": "Freehold",
		"CND": "Condo",
		"COM": "Commercial",
	};

  private criteriaUrl = 'https://backend.visagehome.com/php/criteria.php';
  error = new Subject<string>();
  // private firebaseURL = 'https://visage-82084.firebaseio.com';
  // private postUrl = 'https://admin.visagehome.com/php/getpost.php';

  getUsers(count: number): Observable<WP_Users[]> {
    // console.log(`fetchCriterias: count=${count} `);
    let url = `${this.criteriaUrl}/php/criteria.php?t=u`;
    return this.http.get<WP_Users[]>(url)
    .pipe(
      tap(_ => this.log(`get criteria`)),
      catchError(this.handleError<WP_Users[]>('getUsernames', []))
    );
  }

  getCriterias(username: string, count: number): Observable<Criteria[]> {
    // console.log(`fetchCriterias: count=${count} `);
    let url = `${this.criteriaUrl}/php/criteria.php?t=l`;
    if (username) url += `&username=${username}`;
    // console.log(url);
    return this.http.get<Criteria[]>(url)
    .pipe(
      tap(_ => {
        this.log(`getCriteria username=${username}`)
        // console.log(_);
      }),
      catchError(this.handleError<Criteria[]>('getCriterias', []))
    );
  }

  getCriteria(id: number): Observable<Criteria> {
    // let searchParams = new HttpParams();
    // searchParams = searchParams
    //   .append('print', 'pretty')
    //   // .append('custom','key')
    //   ;
    // console.log("fetchCriteria name=" + name);
    let url = `${this.criteriaUrl}/php/criteria.php?id=${id}`;
    // console.log(url);
    return this.http.get<Criteria>(url)
      .pipe(
        tap(_ => {
          // this.log(`fetched criteria id=${id}`);
        }),
        catchError(this.handleError<Criteria>(`getCriteria id=${id}`))
      );
  }

  /** Post: add a new criteria to the server */
  addCriteria (criteria: Criteria): Observable<any> {
    // console.log("At addCriteria mls="+criteria.ml_num);
    // console.log(this.criteriaUrl);

    return this.http.post<Criteria>(this.criteriaUrl, criteria, httpOptions).pipe(
      tap(_ => this.log(`updated criteria id=${criteria.id}`)),
      catchError(this.handleError<any>('addCriteria', criteria))
    );
  }

  /** PUT: update the criteria */
  updateCriteria (criteria: Criteria): Observable<any> {
    return this.http.put(this.criteriaUrl, criteria, httpOptions).pipe(
      tap(_ => this.log(`updated criteria id=${criteria.id}`)),
      catchError(this.handleError<any>('updateCriteria'))
    );
  }

  /** DELETE: delete the criteria from the server */
  deleteCriteria(id: number): Observable<any> {
    let url = `${this.criteriaUrl}/php/criteria.php?id=${id}`;
    return this.http.delete<Criteria>(url, httpOptions)
    .pipe(
      tap(_ => this.log(`deleted criteria id=${id}`)),
      catchError(this.handleError<any>('deleteCriteria'))
    );
  }

  /* @param operation - name of the operation that failed
  * @param result - optional value to return as the observable result
  */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a BhffService message with the MessageService  */
  private log(message: string) {
    console.log(message);
    //    this.logMessage.add(`CriteriaService: ${message}`);
  }

  private alert(type: string, message: string) {
    console.log(message);
    //    this.alertMessage.add(type, `CriteriaService: ${message}`);

  }

}
