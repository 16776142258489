import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Vow } from 'src/app/data/vow';
import { VowService } from 'src/app/data/vow.service';

@Component({
  selector: 'app-vow-comps',
  templateUrl: './vow-comps.component.html',
  styleUrls: ['./vow-comps.component.scss'],
})
export class VowCompsComponent implements OnInit {
  @Input() vow: Vow;
  cc$: Observable<Vow[]>;
  isLoading = false;
  sameBuilding = true;
  s_r = 'Sold';

  constructor(
    private vowService: VowService,
  ) { }

  ngOnInit() {
    // console.log(this.vow.vow_userrole);
    this.isLoading = true;
    switch (true) {
      case this.vow.vow_class == 'RES' && this.vow.s_r == 'Sale':
        this.s_r = 'Sold';
        this.cc$ = this.vowService.getFreeholdSoldComp(this.vow.ml_num)
          .pipe(
            tap((resp: Vow[]) => {
              this.isLoading = false;
              // this.sameBuilding = resp[0].vow_samebuilding;
            })
          );
        break;
      case this.vow.vow_class == 'CND' && this.vow.s_r == 'Sale':
        this.s_r = 'Sold';
        this.cc$ = this.vowService.getCondoSoldComp(this.vow.ml_num)
          .pipe(
            tap((resp: Vow[]) => {
              this.isLoading = false;
              this.sameBuilding = (resp.length) ? resp[0].vow_samebuilding : true;
            })
          );
        break;
      case this.vow.vow_class == 'RES' && this.vow.s_r == 'Lease':
        this.s_r = 'Leased';
        this.cc$ = this.vowService.getFreeholdLeasedComp(this.vow.ml_num)
          .pipe(
            tap((resp: Vow[]) => {
              this.isLoading = false;
              // this.sameBuilding = resp[0].vow_samebuilding;
            })
          );
        break;
      case this.vow.vow_class == 'CND' && this.vow.s_r == 'Lease':
        this.s_r = 'Leased';
        this.cc$ = this.vowService.getCondoLeasedComp(this.vow.ml_num)
          .pipe(
            tap((resp: Vow[]) => {
              this.isLoading = false;
              this.sameBuilding = (resp.length) ? resp[0].vow_samebuilding : true;
            })
          );
        break;
    }

  }

  // toggleShow() {
  //   this.inMetre = !this.inMetre;
  //   this.dim = this.inMetre ? 'm' : 'ft';
  //   this.dim2 = this.inMetre ? 'm²' : 'ft²';
  // }

  checkScreen() {
    let innerWidth = window.innerWidth;
    switch (true) {
      case innerWidth <= 650:
        return 1;
      default:
        return 2;
    }
  }
}
