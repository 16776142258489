import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Email } from './email';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(
    private http: HttpClient,
  ) { }

  private emailUrl: string = `https://backend.visagehome.com/php/email.php`;

  sendEmail(email:Email ) {

    this.http.post(this.emailUrl, email)
      .subscribe(_ => {
        console.log(_);
      });
  }


  // Common routines

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a BhffService message with the MessageService  */

  private log(message: string) {
    //     this.logMessage.add(`VowService: ${message}`);
  }

  private alert(type: string, message: string) {
    //     this.alertMessage.add(type, `VowService: ${message}`);

  }
}
