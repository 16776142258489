export class Mortgage {
    downpct: number;
    periodic: string ;
    down: number;
    loan: number;
    cmhc: number;
    principal: number;
    periodicPayment: number;
    totalPayment: number;
    totalInterest: number;
    numberPayments: number;
    numberYears: number;
  }
  