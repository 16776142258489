// import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpParams, HttpEventType, HttpEvent, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { tap, take, exhaustMap, catchError, switchMap, map } from 'rxjs/operators';
// import { AuthenService } from './authen.service';
// import { User } from './user';
import { Observable, of, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { AdminService } from '../data/admin.service';
@Injectable({
  providedIn: 'root'
})

// move logging to logging-interceptor service
// intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>

export class AuthenInterceptorService implements HttpInterceptor {
  constructor(
    private adminService: AdminService,
    // private router: Router,
    // private route: ActivatedRoute,
    // private location: Location
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log(`intercept ${req.url}`);

    if (req.url.match(/firebaseio.com/ig)) {
      // console.log('process firebaseio.com');
      return this.adminService.adminToken().pipe(
        switchMap(token => {
          // console.log("token="+token.substr(0,12));

            const modifiedRequest = req.clone({
              params: new HttpParams()
                .set('auth', token)
              // .set('observe','response')
            })
            // console.log(modifiedRequest.url);
            return next.handle(modifiedRequest);
        })
      )
    }

    if (req.url.match(/blog.visagehome.c/ig)) {
      // console.log(req.url);
      // console.log('outgoing blog.visagehome.com request');
      let authdata = btoa('admin:$S2533021h');
      // let token = localStorage.getItem('jwt-wp');
      const modifiedRequest = req.clone({
        setHeaders: {
          Authorization: `Basic ${authdata}`
        },
      })
      return next.handle(modifiedRequest);
    }

    if (req.url.match(/house2condo.com/ig)) {
      // console.log(req.url);
      console.log('outgoing house2condo.com request');
      let authdata = btoa('wpjson:S2533021h');
      console.log(authdata);
      // let token = localStorage.getItem('jwt-wp');
      const modifiedRequest = req.clone({
        setHeaders: {
          Authorization: `Basic ${authdata}`
        },
      })
      return next.handle(modifiedRequest);
    }

    if (req.url.match(/onlineincomedeal.com/ig)) {
      // console.log('outgoing blog.visagehome.com request');
      let authdata = btoa('Christine:9YOHptrSmS#Z ');
      const modifiedRequest = req.clone({
        setHeaders: {
          Authorization: `Basic ${authdata}`
        },
      })
      return next.handle(modifiedRequest);
    }

    return next.handle(req);

  }

  // handleError(err: HttpErrorResponse): Observable<any> {
  //   if (err.status === 401 || err.status === 403) {
  //     console.log(err.status);
  //     console.log(err.headers);
  //     return throwError(err);
  //   }
  // }


}
