import { formatNumber } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Vow } from 'src/app/data/vow';

@Component({
  selector: 'app-vow-feature-comm',
  templateUrl: './vow-feature.component.html',
  styleUrls: ['./vow-feature.component.scss'],
})
export class VowFeatureCommComponent implements OnInit {
  @Input() vow: Vow;

  compass = {
    'N ': "North", 'S ': "South", 'E ': "East", 'W ': "West",
    'Ne': "North East", 'Nw': "North West", 'Se': "South East", 'Sw': "South West"
  }
  frontOn = { 'N': "North", 'S': "South", 'E': "East", 'W': "West" };
  features = [];

  constructor() {
  }

  ngOnInit() {

    this.features = [
      {
        class: ['COM'],
        label: 'Total Area',
        value: formatNumber(this.vow.tot_area,'en-ca','0.0-2'),
      },
      {
        class: ['COM'],
        label: 'Office/Apartment Area',
        value: formatNumber(this.vow.oa_area,'en-ca','0.0-2'),
      },
      {
        class: ['COM'],
        label: 'Industrial Area',
        value: formatNumber(this.vow.ind_area,'en-ca','0.0-2'),
      },
      {
        class: ['COM'],
        label: 'Retail Area',
        value: formatNumber(this.vow.retail_a,'en-ca','0.0-2'),
      },
      {
        class: ['COM'],
        label: 'Volts',
        value: this.vow.volts,
      },
      {
        class: ['COM'],
        label: 'Amperes',
        value: this.vow.amps,
      },
      {
        class: ['COM'],
        label: 'Zoning',
        value: this.vow.zoning,
      },
      {
        class: ['COM'],
        label: 'Survey',
        value: this.vow.survey,
      },
      {
        class: ['COM'],
        label: 'Lot/Bulg/Unit/Dim',
        value: formatNumber(this.vow.front_ft,'en-ca','1.0-2')
        + ' x ' + formatNumber(this.vow.depth,'en-ca','1.0-2') + ' ' + this.vow.lotsz_code + ' '
        + this.vow.lot_code,
      },
      {
        class: ['COM'],
        label: 'Heat',
        value: this.vow.heating,
      },
      {
        class: ['COM'],
        label: 'Freestanding',
        value: this.vow.freestandg,
      },
      {
        class: ['COM'],
        label: 'A/C',
        value: this.vow.a_c
      },
      {
        class: ['COM'],
        label: 'Water',
        value: this.vow.water
      },
      {
        class: ['COM'],
        label: 'Sewers',
        value: this.vow.sewer
      },
      {
        class: ['COM'],
        label: 'Garage',
        value: this.vow.gar_type
      },
      {
        class: ['COM'],
        label: 'Parking Spaces',
        value: formatNumber(this.vow.park_spcs,'en-ca','0.0-0'),
      },
      {
        class: ['COM'],
        label: 'Chattels',
        value: this.vow.chattels,
      },
      {
        class: ['COM'],
        label: 'LLBO',
        value: this.vow.llbo,
      },
      {
        class: ['COM'],
        label: 'Days Open',
        value: this.vow.days_open,
      },
      {
        class: ['COM'],
        label: 'Hours Open',
        value: this.vow.hours_open,
      },
      {
        class: ['COM'],
        label: 'Employees',
        value: formatNumber(this.vow.employees,'en-ca','0.0-0'),
      },
      {
        class: ['COM'],
        label: 'Seats',
        value: formatNumber(this.vow.seats,'en-ca','0.0-0'),
      },
      {
        class: ['COM'],
        label: 'Area Infl',
        textarea: true,
        value: this.vow.area_infl1_out + ' ' + this.vow.area_infl2_out,
      },
      {
        class: ['COM'],
        label: 'Cross St',
        value: this.vow.cross_st,
      },
      {
        class: ['COM'],
        label: 'Franchise',
        value: this.vow.franchise,
      },
      {
        class: ['COM'],
        label: 'Occupancy',
        value: this.vow.occ,
      },
      {
        class: ['COM'],
        label: 'Taxes',
        value: '$'+formatNumber(this.vow.taxes,'en-ca','1.2-2')+' / '+
        this.vow.yr + ' / ' +this.vow.type_taxes,
      },

    ]
  }

}
