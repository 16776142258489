import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Website } from './website';
// import { MessagesService } from '../messages/messages.service';
import { Observable, of, Subject, throwError } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
// import { WebsiteComponent } from '../website/website.component';

const httpOptions = {
  headers: new HttpHeaders(
    { 'Content-type': 'application/json' }
  )
};

@Injectable({
  providedIn: 'root'
})

export class WebsiteService {
  
  constructor(
    private http: HttpClient,
    // private myService: MessagesService,
  ) { }
  website$: Observable<{ [key: number]: Website }[]>;
  website: Website;
  // firebaseID : string;

  error = new Subject<string>();
  
  private firebaseURL = 'https://visage-82084.firebaseio.com';
  private websiteUrl = 'https://backend.visagehome.com/php/website.php';
  
  getWebsiteByDomain(domain: string): Observable<Website> {
    // this.getDomainName();
    let url = `${this.websiteUrl}`;
    
    url += `?domain=${domain}`;
    
    console.log(url);
    return this.http.get<Website>(url)
    .pipe(
      tap(_ => this.log(`fetched website domain=${domain}`)),
      catchError(this.handleError<Website>(`getWebsiteByDomain domain=${domain}`))
    );
  }

  getWebsites(): Observable<Website[]> {
    let url = `${this.websiteUrl}?t=l`;
    // console.log(url);
    return this.http.get<Website[]>(url)
      .pipe(
        tap(_ => this.log(`fetched websites `)),
        catchError(this.handleError<Website[]>('getWebsites',[]))
      );
  }

  getWebsite(id: number): Observable<Website> {
    const url = `${this.websiteUrl}?lid=${id}`;
    // console.log(url);
    const params = new HttpParams()
      .set('id', `${id}`);
    return this.http.get<Website>(this.websiteUrl, {params})
    .pipe(
      tap(_ => this.log(`fetched website id=${id}`)),
      catchError(this.handleError<Website>(`getWebsite id=${id}`))
    );
  }

  fetchWebsites(): Observable<Website[]> {
    return this.http
      .get<Website[]>(
        `${this.firebaseURL}/website.json`,
      )
      .pipe(
        map((responseData: { [key: number]: Website }) => {
          // console.log(responseData);
          const websiteArray: Website[] = [];
          for (const key in responseData) {
            if (responseData.hasOwnProperty(key)) {
              if (responseData[key]) {
                websiteArray.push(responseData[key]);
              }
            }
          }
          // console.log(websiteArray);
          return websiteArray;
        }),
        catchError(errorRes => {
          // Send to analytics server
          return throwError(errorRes);
        })
      )
  }

  fetchWebsite(domain:string):Observable<Website> {
    domain = domain.replace(/\./g,'-');
    // console.log(`fetchWebsite ${domain}`);
    let url = `${this.firebaseURL}/website/${domain}.json`;
    // console.log(url);
    return this.http.get<Website>(url).pipe(
        map(resp => {
          // console.log(resp);
          return resp;
        }),
        catchError(errorRes => {
          // Send to analytics server
          // console.log(errorRes);
          return throwError(errorRes);
        })
      )

  }

  fetchWebsiteByDomain(domain:string):Observable<Website> {
    domain = domain.replace(/\./g,'-');
    let url = `${this.firebaseURL}/website.json`;
    if (domain) url += `?orderBy="web_domain"&equalTo="${domain}"`;

    return this.http
      .get< Website >(url)
      .pipe(
        map(responseData => {
          // console.log(responseData);
          for (const key in responseData) {
            if (responseData.hasOwnProperty(key)) {
              if (responseData[key]) {
                return (responseData[key]);
              }
            }
          }
        }),
        catchError(errorRes => {
          // Send to analytics server
          return throwError(errorRes);

        })
      )
  }

  transferWebsite (id: number):void {
    console.log(`At transferWebsite id=${id}`);
    this.getWebsite(id)
      .forEach((rec: Website) =>{
        this.addFirebaseWebsite(rec);
        console.log("posted to firebase id="+id) ;

      });
  }

  transferWebsites():void {
    console.log(`At transferWebsites`);
    this.getWebsites()
      .forEach((rec: Website[]) =>{
        for (let i=0; i<rec.length; i++) {
          this.addFirebaseWebsite(rec[i]);
          console.log("website posted to firebase id="+rec[i].web_id) ;
        }
      });
  }

  addFirebaseWebsite(website: Website):void {   
    // let key = website.web_id;
    // let postData = { [website.web_id] : website } ;
    delete website["web_user"];
    delete website["web_ip"];
    delete website["web_trx_date"];
    website["web_status"] = 'D';
    this.http.put<any>(
        `${this.firebaseURL}/website/${website.web_domain}.json`,
        website
      )
      .subscribe(
        responseData => {
          console.log(responseData);
          // this.deActivateWebsite(website.web_id);
        },
        error => {
          this.error.next(error.message);
        }
      );
  }

  //////// Save methods //////////

  initWebsite(): Observable<Website> {
    // return new Observable<Website>();
    return of(new Website());
    
  }
  
  /** POST: add a new website to the server */
  addWebsite (website: Website): Observable<Website> {
    // console.log("At addWebsite title="+website.web_title);
    let domain = website.web_domain.replace(/\./g,'-');
    // return this.http.post<Website>(this.websiteUrl, website, httpOptions)
    return this.http.put<any>(`${this.firebaseURL}/website/${domain}.json`,
    website
    ).pipe(
      // tap((new: Website) => this.log(`added website w/ id=${new.mkt_id}`)),
      catchError(this.handleError('addWebsite', website))
    );
  }

  /** PUT: update the hero on the server */

  updateWebsite(website: Website): Observable<any> {
    let domain = website.web_domain.replace(/\./g,'-');
    // console.log(`domain=${domain}`);
    return this.http.put<any>(`${this.firebaseURL}/website/${domain}.json`,
      website
    ).pipe(
      tap(_ => this.log(`updated website domain=${website.web_domain}`)),
      catchError(this.handleError<any>('updateWebsite'))
    );
  }

  /** DELETE: delete the website from the server */
  deleteWebsite (domain: string): Observable<any> {
    // const url = `${this.websiteUrl}?website=${id}`;
    domain = domain.replace(/\./g,'-');
    return this.http.delete<any>(`${this.firebaseURL}/website/${domain}.json`).pipe(
    // return this.http.delete<Website>(url, httpOptions).pipe(
      tap(_ => this.log(`deleted website domain=${domain}`)),
      catchError(this.handleError<Website>('deleteWebsite'))
    );
  }

  /* @param operation - name of the operation that failed
  * @param result - optional value to return as the observable result
  */

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a BhffService message with the MessageService  */
  private log(message: string) {
    this.error.next(`WebsiteService: ${message}`);
  }

}
