import { Component, OnInit, Input } from '@angular/core';
import { Mortgage } from '../../data/mortgage';
import { SettingService } from '../../data/setting.service';
import { Setting } from '../../data/setting';

@Component({
  selector: 'app-mortgage-calculator',
  templateUrl: './mortgage.component.html',
  styleUrls: ['./mortgage.component.scss']
})
export class MortgageComponent implements OnInit {
  @Input() price: number;

  int: number = 2;

  down: number = 0;
  downpct: number = 20;

  year: number = 25;
  freq: string = "m";
  // periodic: string = "Monthly";
  minDown: number = 0;
  loan: number = 0;
  cmhcRate: number = 0;
  cmhc: number = 0;

  mortgage = new Mortgage();

  constructor(
    private settingService: SettingService,
  ) { }

  ngOnInit(): void {
    this.settingService.getSetting()
      .subscribe((resp: Setting) => {
        // console.log(resp);
        this.price = Math.round(this.price);
        this.int = resp.interest;
        // console.log('int='+this.int)
        this.downpct = 20;
        this.down = this.price * this.downpct / 100;
        this.calcMortgage();
      })

  }
  priceChanged() {
    console.log('check price')
    if (this.price > 1000000) {
      // console.log('>1mil');
      if (this.downpct < 20) {
        // this.waitSecond();
        this.downpct = 20;
        this.down = this.price * this.downpct / 100;
      } else {
        // this.waitSecond();
        this.down = this.price * this.downpct / 100;
      }
    } else if (this.price > 500000) {
      // console.log('>500k');
      this.down = this.price * this.downpct / 100;
      if (this.downpct < 10) {
        this.minDown = 500000 * this.downpct / 100 + (this.price - 500000) * 0.1;
      }
      if (this.down < this.minDown) {
        this.down = this.minDown;
      }
    } else {
      // console.log('default down');
      this.down = this.price * this.downpct / 100;
    }
    this.calcMortgage();
  }

  checkDownpayment() {
        console.log('check percent')
        if (this.downpct < 20) {
          if (this.price > 1000000) {
            this.downpct = 20;
            this.down = this.price * this.downpct / 100;
          } else if (this.price > 500000) {
            this.down = this.price * this.downpct / 100;
            if (this.downpct < 10) {
              this.minDown = 500000 * this.downpct / 100 + (this.price - 500000) * 0.1;
              if (this.down < this.minDown) {
                this.down = this.minDown;
              }
            }
          } else { // price <=500k
            this.down = this.price * this.downpct / 100;
          }
        } else { // percent >=20
          // this.waitSecond();
          this.down = this.price * this.downpct / 100;
        }
    this.calcMortgage();
  }

  calcMortgage() {
    // console.log('calc int='+this.int)
    // Yearly interest rate in decimal
    let interestRate = this.int / 100;
    // console.log(`interestRate=${interestRate}`);
    let amortization = this.year;
    let periodsPerMonth = 1;
    let paymentsPerYear = 12;
    switch (this.freq) {
      case "m": // Monthly
        this.mortgage.periodic = "Monthly";
        periodsPerMonth = 1;
        paymentsPerYear = 12;
        this.mortgage.numberPayments = amortization * 12;
        break;
      case "sm": // Semi-monthly
        this.mortgage.periodic = "Semi-monthly";
        periodsPerMonth = 2;
        paymentsPerYear = 24;
        this.mortgage.numberPayments = amortization * 24;
        break;
      case "abw": // Bi-weekly accel
        this.mortgage.periodic = "Bi-weekly Accel";
        periodsPerMonth = 2;
        paymentsPerYear = 26;
        this.mortgage.numberPayments = amortization * 26;
        break;
    }

    this.mortgage.downpct = this.downpct;

    this.mortgage.loan = this.price - this.down;
    if (this.downpct <= 5) {
      this.cmhcRate = 4;
    } else if (this.downpct <= 10) {
      this.cmhcRate = 3.1;
    } else if (this.downpct <= 15) {
      this.cmhcRate = 2.8;
    } else if (this.downpct < 20) {
      this.cmhcRate = 2.4;
    } else {
      this.cmhcRate = 0;
    }
    this.mortgage.cmhc = this.mortgage.loan * this.cmhcRate / 100;
    this.mortgage.principal = this.mortgage.loan + this.mortgage.cmhc;
    // Canadian monthly interest rate (not American)
    let monthlyInterestRate = Math.pow(1 + interestRate / 2, 2 / 12) - 1;
    // console.log(`monthlyInterestRate=${monthlyInterestRate}`);
    let effectiveInterestRate = monthlyInterestRate * 12;
    // console.log(`effectiveInterestRate=${effectiveInterestRate}`);
    let monthlyPayment = this.mortgage.principal * monthlyInterestRate
      / (1 - Math.pow(1 + monthlyInterestRate, - amortization * 12));
    // console.log(`monthlyPayment=${monthlyPayment}`);
    this.mortgage.periodicPayment = monthlyPayment / periodsPerMonth;
    // console.log(`periodicPayment=${this.mortgage.periodicPayment}`);
    let period = - Math.log(1 - this.mortgage.principal / this.mortgage.periodicPayment
      * effectiveInterestRate / paymentsPerYear)
      / Math.log(1 + effectiveInterestRate / paymentsPerYear);
    this.mortgage.numberYears = period / paymentsPerYear;
    this.mortgage.totalPayment = this.mortgage.periodicPayment * period;
    this.mortgage.totalInterest = this.mortgage.totalPayment - this.mortgage.principal;

    // Round numbers
    this.mortgage.principal = Math.round(this.mortgage.principal * 100) / 100;
    this.mortgage.numberYears = Math.round(this.mortgage.numberYears * 10) / 10;
    this.mortgage.periodicPayment = Math.round(this.mortgage.periodicPayment * 100) / 100;
    this.mortgage.totalPayment = Math.round(this.mortgage.totalPayment * 100) / 100;
    this.mortgage.totalInterest = Math.round(this.mortgage.totalInterest * 100) / 100;

  }

  upDown() {
    this.down = this.price * this.downpct / 100;
    this.calcMortgage();
  }

  upPct() {
    this.downpct = this.down / this.price * 100;
    this.calcMortgage();
  }


}
