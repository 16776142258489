import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
// import { LoginComponent } from './login/login.component';
import { MortgageComponent } from './Calculator/mortgage.component';
import { VowMortgageComponent } from './vow-mortgage/vow-mortgage.component';
import { VowRoomsComponent } from './vow-rooms/vow-rooms.component';
import { VowCommerceComponent } from './vow-commerce/vow-commerce.component';
import { LoginComponent } from './login/login.component';
import { IonicModule } from '@ionic/angular';
import { VowFeatureHomeComponent } from './vow-feature/vow-feature-home.component';
import { VowFeatureCommComponent } from './vow-feature/vow-feature-comm.component';
import { VowMainComponent } from './vow-main/vow-main.component';
// import { VowResscComponent } from './vow-ressc/vow-ressc.component';
// import { VowReslcComponent } from './vow-reslc/vow-reslc.component';
// import { VowCndscComponent } from './vow-cndsc/vow-cndsc.component';
// import { VowCndlcComponent } from './vow-cndlc/vow-cndlc.component';
import { VowCompsComponent } from './vow-comps/vow-comps.component';
import { VowHotlistComponent } from './vow-hotlist/vow-hotlist.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { VowFilterComponent } from './vow-filter/vow-filter.component';
// import { LoginComponent } from '../authen/login.component';

@NgModule({
  declarations: [
    LoginComponent,
    LoadingSpinnerComponent,
    MortgageComponent,
    VowMainComponent,
    VowMortgageComponent,
    VowRoomsComponent,
    VowCommerceComponent,
    VowFeatureHomeComponent,
    VowFeatureCommComponent,
    VowCompsComponent,
    VowHotlistComponent,
    VowFilterComponent,
    ContactFormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
  ],
  exports: [
    LoginComponent,
    LoadingSpinnerComponent,
    MortgageComponent,
    VowMainComponent,
    VowMortgageComponent,
    VowRoomsComponent,
    VowCommerceComponent,
    VowFeatureHomeComponent,
    VowFeatureCommComponent,
    VowCompsComponent,
    VowHotlistComponent,
    VowFilterComponent,
    ContactFormComponent,
  ]
})
export class SharedModule { }
