export class Hotlist {
    id: string = null;
    name: string = null;
    class: string = 'RES,CND';
    featured: boolean = null;
    color: string = 'primary';
    area: string = null;
    municipality: string[] = null;
    type: string[] = null;
    style: string[] = null;
    keywords: string = null;
    min_price: number = null;
    max_price: number = null;
    min_bed: number = null;
    max_bed: number = null;
    min_wash: number = null;
    max_wash: number = null;
    
}
